
      ;
      $app_version: 'v1.0.0_5807321';
      $app_name: 'website';
      
@import "../../styles/utils.scss";



.menu {
  a {
    display: inline-block;
    color: $heading;
  }

  .active {
    text-shadow: 0px 0px 1px black;
    position: relative;
  }

  label {
    &::after {
      background: #F9E72C;
      content: '';
      display: inline-block;
      position: absolute;
      left: 0;
      right: 0;
      z-index: 9;
    }
  }

  a {
    span {
      position: relative;

      &::after {
        background: #F9E72C;
        content: '';
        display: inline-block;
        position: absolute;
        left: 0;
        right: 0;
        z-index: 9;
      }
    }
  }
}

.indicator {
  display: none;
}

.localer {
  .locale {
    color: #999999;
    cursor: pointer;

    &.active {
      color: $heading;
    }

    &+.locale {
      margin-left: 8px;

      &::before {
        content: '/';
        display: inline-block;
        color: $heading;
        margin-right: 8px;
      }
    }
  }
}

@include s-large {
  .box {
    border-bottom: 1px solid #F5F5F4;
  }

  .menuIcon,
  .closeIcon {
    display: none;
  }

  .AppHeader {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 44px 0 28px;
  }

  .logo {
    flex: 0 0 auto;
    width: 152px;
    height: 54px;
  }

  .menus {
    flex: 1 1 auto;
    max-width: 715px;
    height: 42px;
    margin-left: 40px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .menu {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    &:last-child {
      &:hover {
        .subMenus {
          left: auto;
          right: 0;
        }
      }
    }

    &:hover {
      .menuTitle {
        &:after {
          width: 100%;
          height: 3px;
          bottom: -10px;
        }
      }
      .subMenus {
        display: block;
      }
    }

    .menuBox {
      position: relative;
    }

    .activeIndicator {
      color: transparent;
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      pointer-events: none;
    }

    label {
      cursor: pointer;
    }

    label,
    a {

      &:hover,
      &.active {
        span {
          &::after {
            width: 100%;
            height: 3px;
            bottom: -10px;
          }
        }
      }
    }
  }

  .indicator {
    &:checked {
      &~.subMenus {
        display: block;
      }

      &+label {
        &::after {
          width: 24px;
          height: 3px;
          bottom: -10px;
        }
      }
    }
  }

  .title {
    &.inactive {
      &:hover,
      &.active {
        span {
          &::after {
            width: 0;
            height: 0;
          }
        }

        &+label {
          text-shadow: 0px 0px 1px black;
          &::after {
            width: 0;
            height: 0;
          }
        }
      }
    }
  }

  .subMenus {
    position: absolute;
    z-index: 8;
    background-color: #fff;
    padding: 4px 0;
    top: 30px;
    left: 0;
    display: none;
    box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.15);

    .subMenuItem {
      padding: 0 12px;

      .name {
        display: block;
        line-height: 2.25;
        white-space: nowrap;
      }

      .subMenu {
        padding-left: 20px;
      }
    }

    .subMenu {
      white-space: nowrap;
      color: #666;

      &.alone {
        padding: 0 12px;
        color: #333;
      }

      a {
        line-height: 2.25;
        display: inline-block;
        width: 100%;

        &:hover {
          text-shadow: 0px 0px 1px black;
        }

        &::after {
          display: none;
        }
      }
    }
  }
  .localer {
    position: absolute;
    top: 24px;
    right: 0;
    font-size: 14px;
    line-height: 20px;
  }
}

@include s-small {
  .box {
    background-color: $body-bg;
    border-bottom: 1px solid #F5F5F4;
  }

  .logo {
    margin: 0 auto;
    width: 100%;
    padding: PX2REM(36px) 0 PX2REM(20px);
    position: relative;
    text-align: center;

    img {
      width: PX2REM(152px);
      height: PX2REM(54px);
    }

    .menuIcon {
      display: block;
      position: absolute;
      right: PX2REM(40px);
      top: 50%;
      transform: translateY(-50%);
      font-size: PX2REM(36);
    }
  }

  .menusBox {
    padding-right: PX2REM(40);
    display: none;

    &.open {
      display: block;
    }
  }

  .menus {
    background-color: $body-bg;
    width: 100%;
    display: flex;
    flex-direction: column;
    padding-left: PX2REM(40px);

    a {
      display: inline-block;
      width: 100%;
    }

    .menu {
      flex: 0 0 auto;
      position: relative;
      font-size: PX2REM(32px);

      .title {
        padding: PX2REM(25px) 0;
      }

      .arrow {
        position: absolute;
        top: PX2REM(31px);
        right: 0;
        font-size: PX2REM(32px);
        line-height: PX2REM(38px);

        &.inverts {
          transform: rotate(180deg);
        }
      }
    }

    .divider {
      border-bottom: 1px #F0F0F0 solid;
    }

    .subMenus {
      padding: PX2REM(25px) 0 PX2REM(25px) PX2REM(40px);
    }

    .closeIcon {
      position: fixed;
      z-index: 9;
      top: PX2REM(44px);
      right: PX2REM(44px);
    }
  }

  .localer {
    line-height: 4;
    padding-right: PX2REM(40px);
    display: flex;
    justify-content: flex-end;

    &.hide {
      display: none;
    }
  }
}