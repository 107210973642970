
      ;
      $app_version: 'v1.0.0_5807321';
      $app_name: 'website';
      
@import '../../styles/utils';

.paginate {
  display: flex;
  justify-content: center;

  .list {
    max-width: 1200px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;

    .item {
      flex: 0 0 36px;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 36px;
      height: 36px;
      background: #FFFFFF;
      border-radius: 2px;
      border: 1px solid #d5d5d5;
      margin: 0 4px;
      cursor: pointer;
      font-size: 14px;
      color: #222222;
      user-select: none;

      img {
        width: 12px;
        height: 12px;
      }

      &.active {
        background: #F9E72C;
        border: 1px solid #F9E72C;
      }
    }
  }
}
