
      ;
      $app_version: 'v1.0.0_5807321';
      $app_name: 'website';
      
@import "../../../../styles/utils.scss";


.title {
  color: #222222;
  font-weight: bolder;
}

.main {
  display: flex;
  justify-content: space-between;

  .left, .right {
    color: #fff;

    .channel {
      font-weight: bold;
    }

    .section {
      .name {
        font-weight: bold;
      }
    }
  }

  .right {
    .channel {
      color: #333333;
    }

    .section {
      .name {
        color: #333;
      }

      .description {
        color: #666;
      }
    }
  }

  .separate {
    align-self: center;
    width: 43px;
    height: 20px;
  }
}

@include s-large {
  .title {
    font-size: 20px;
    line-height: 30px;
    width: $pc-max-width;
    margin: 60px auto 24px;
  }
  .business {
    position: relative;
    width: 100%;

    &:before, &:after {
      content: '';
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      z-index: -1;
    }

    &:before {
      background: linear-gradient(180deg, #FA884C 0%, #EB4B40 100%);
      clip-path: polygon(0 0, 53% 0, 47% 100%, 0 100%);
    }

    &:after {
      background: #F5F6FA;
      clip-path: polygon(53% 0, 100% 0, 100% 100%, 47% 100%);
    }

    .main {
      width: $pc-max-width;
      margin: 0 auto;
      display: flex;
      justify-content: space-between;
      padding: 40px 0;

      .left, .right {
        padding-left: 60px;
        color: #fff;
        flex: 0 0 45%;

        .channel {
          font-size: 20px;
          line-height: 30px;
          margin-bottom: 24px;
        }

        .section {
          margin-bottom: 20px;

          .name {
            font-size: 16px;
            line-height: 28px;
          }

          .description {
            font-size: 14px;
            line-height: 22px;
          }
        }
      }
    }
  }
}

@include s-middle {
  .title {
    padding-left: $middle-padding;
  }
  .business {
    .main {
      .right {
        padding-left: 0;
      }
    }
  }
}

@include s-small {
  .container {
    margin: 0 PX2REM(40px);
    position: relative;
    padding-bottom: PX2REM(58px);

    &:before,
    &:after {
      content: '';
      position: absolute;
      left: 0;
      right: 0;
      background-color: var(--theme);
    }

    &:before {
      bottom: 6px;
      height: 2px;
    }

    &:after {
      bottom: 0;
      height: 4px;
      border-radius: 0 0 5px 5px;
    }
  }
  .title {
    margin: PX2REM(100px) auto PX2REM(40px);
    font-size: PX2REM(40px);
  }
  .separate {
    display: none;
  }
  .business {
    position: relative;
    width: 100%;

    .main {
      display: flex;
      flex-direction: column;

      .left {
        background: linear-gradient(180deg, #FA884C 0%, #EB4B40 100%);
        margin-bottom: PX2REM(40px);
        .description {
          color: rgba(255, 255, 255, .7);
        }
      }
      .right {
        background: #F5F6FA;
      }

      .left, .right {
        color: #fff;
        padding: PX2REM(40px);

        .channel {
          font-size: 20px;
          line-height: 30px;
          margin-bottom: 24px;
        }

        .section {
          margin-bottom: 20px;

          .name {
            font-size: 16px;
            line-height: 28px;
          }

          .description {
            font-size: 14px;
            line-height: 22px;
          }
        }
      }
    }
  }
}
