
      ;
      $app_version: 'v1.0.0_5807321';
      $app_name: 'website';
      
@import "../../../../styles/utils.scss";

.Introduce {
  .main {
    .title {
      color: #222222;
      font-weight: bold;
    }

    .container {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;

      .detail {
        .description {
          color: #666666;
        }
      }
    }
  }
}

@include s-large {
  .Introduce {
    margin-bottom: 60px;

    &:last-child {
      margin-bottom: 0;
    }

    .main {
      width: $pc-max-width;
      margin: 0 auto;

      .title {
        font-size: 20px;
        margin-bottom: 24px;
      }

      .container {
        &.reverse {
          flex-direction: row-reverse;
          .cover {
            margin-right: 50px;
          }
          .detail {
            margin-right: 0;
          }
        }
        .detail {
          flex: 0 0 60%;
          width: 60%;
          margin-right: 50px;

          &.bigImage {
            flex: 0 0 33.33%;
            width: 33.33%;
          }

          .description {
            font-size: 16px;
            line-height: 28px;
            margin-bottom: 40px;
          }
        }

        .cover {
          flex: 1;
          width: 0;

          img {
            width: 100%;
            height: 100%;
          }
        }
      }
    }
  }
}

@include s-middle {
  .Introduce {
    .main {
      padding-left: $middle-padding;
      padding-right: $middle-padding;
    }
  }
}

@include s-small {
  .Introduce {
    margin-bottom: PX2REM(100px);
    &:last-child {
      margin-bottom: PX2REM(40px);
    }

    .main {
      .title {
        font-size: PX2REM(40px);
        margin-bottom: PX2REM(40px);
      }

      .container {
        display: flex;
        flex-direction: column;

        .detail {
          .description {
            font-size: PX2REM(32px);
            line-height: PX2REM(48px);
            margin-bottom: PX2REM(40px);
          }
        }

        .cover {
          width: 100%;

          img {
            width: 100%;
            height: 100%;
          }
        }
      }
    }
  }
}
