
      ;
      $app_version: 'v1.0.0_5807321';
      $app_name: 'website';
      
@import "../../../../styles/utils.scss";

@include s-large {
  .main {
    display: flex;
    align-items: flex-start;
    padding: 40px 0;

    .info {
      flex: 1;
      margin-right: 50px;
      flex-shrink: 0;

      .title {
        font-size: 24px;
        color: #333333;
        line-height: 32px;
        margin-bottom: 24px;
        font-weight: bold;
      }

      .subTitle {
        font-size: 16px;
        color: #333333;
        line-height: 28px;
        margin-bottom: 10px;
      }

      .description {
        font-size: 16px;
        color: #666666;
        line-height: 28px;
        margin-bottom: 20px;

        .item {
          .name {
            display: inline;
            flex-shrink: 0;
            font-size: 16px;
            margin-right: 5px;
          }

          .values {
            display: inline;
            .value {
              display: inline;
              font-size: 16px;
              margin-right: 5px;
            }
          }
        }
      }

      .dots {
        display: flex;
        margin-bottom: 45px;

        .dot {
          width: 10px;
          height: 10px;
          border-radius: 50%;
          background: #E5E5E5;
          margin-right: 6px;

          &.active {
            background: #EC4E41;
          }
        }
      }
    }

    .cover {
      flex: 0 0 440px;
      width: 440px;
    }
  }
}

@include s-middle {
  .main {
    padding: $middle-padding;

    .cover {
      width: 0;
    }
  }
}

@include s-small {
  .main {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    padding: PX2REM(40px) PX2REM(40px) 0;
    background: #F5F6FA;

    .info {
      flex: 1;

      .title {
        font-size: PX2REM(36px);
        color: #333333;
        line-height: PX2REM(54px);
        margin-bottom: PX2REM(20px);
        font-weight: bold;
      }

      .subTitle {
        font-size: PX2REM(32px);
        color: #333333;
        line-height: PX2REM(48px);
        margin-bottom: PX2REM(20px);
        font-weight: bold;
      }

      .description {
        margin-bottom: PX2REM(40px);

        .item {
          margin-bottom: PX2REM(20px);

          .name {
            font-size: PX2REM(32px);
            color: #333333;
          }

          .values {
            .value {
              font-size: PX2REM(32px);
              color: #666666;
            }
          }
        }
      }
    }
    .cover {
      width: 100%;
      margin-bottom: PX2REM(20px);
    }
  }
  .swiperDots {
    padding: 0 PX2REM(40px) PX2REM(1px);
  }
  .dots {
    display: flex;
    margin-bottom: PX2REM(60px);

    .dot {
      width: PX2REM(16px);
      height: PX2REM(16px);
      border-radius: 50%;
      background: #E5E5E5;
      margin-right: PX2REM(10px);

      &.active {
        background: #EC4E41;
      }
    }
  }
}
