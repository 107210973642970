
      ;
      $app_version: 'v1.0.0_5807321';
      $app_name: 'website';
      
@import '../../styles/utils.scss';
@import '../../styles/variable.scss';

@include s-large {
  .breadcrumb {
    padding-bottom: 0;
  }
  .banner {
    height: 180px;
    img {
      width: auto;
      height: 100%;
      max-height: 100%;
      object-fit: cover;
    }
  }
  .body {
    max-width: $pc-max-width;
    margin: 0 auto;
    padding: 0 0 70px;

    .title {
      font-size: 20px;
      margin-top: 60px;
      display: block;
    }

    .subTitle {
      font-size: 16px;
    }

    .content {
      font-size: 16px;
      margin-top: 20px;
    }

    .divider {
      margin-top: 32px;
      &.bottomGap {
        margin-bottom: 32px;
      }
    }

    .image {
      width: 360px;
      height: 200px;
    }

    .algorithmImg {
      width: 480px;
      height: 280px;
    }
  }
}

@include s-small {
  .banner {
    height: PX2REM(360px);
    img {
      width: auto;
      height: 100%;
      max-height: 100%;
      object-fit: cover;
    }
  }
  .body {
    margin-top: PX2REM(80px);
    padding-bottom: PX2REM(160px);

    .title {
      display: block;
      font-size: PX2REM(40px);
      margin: PX2REM(100px) PX2REM(40px) 0;
    }

    .subTitle {
      display: block;
      font-size: PX2REM(32px);
      margin-top: PX2REM(60px);
      color: #333;
    }

    .content {
      font-size: PX2REM(32px);
      margin: PX2REM(40px) PX2REM(40px) 0;
      color: #666;
    }

    .divider {
      margin: PX2REM(72px) PX2REM(40px);
    }

    .algorithm {
      margin-top: PX2REM(100px);
    }

    .inspection {
      margin-top: 0;
    }

    .image {
      margin-top: PX2REM(60px);
    }

    .algorithmImg {
      margin-top: PX2REM(60px);
      width: PX2REM(670px);
      height: PX2REM(372px);
    }

    .grid {
      padding: 0 PX2REM(40px);
    }
  }
}
