
      ;
      $app_version: 'v1.0.0_5807321';
      $app_name: 'website';
      
@import "../../../styles/utils.scss";

.ESG {
  color: #666666;
}

@include s-large {
  .description, .cover {
    margin-bottom: 24px;
  }
  .section {
    line-height: 28px;
  }
  .footer {
    margin-top: 20px;
  }
  .part {
    &+.part {
      margin-top: 20px;
    }
  }
}

@include s-middle {

}

@include s-small {
  .description, .cover {
    margin-bottom: PX2REM(40px);
  }
  .cover {
    width: 100%;
    height: 420px;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .section {
    line-height: 28px;

    &:last-child {
      margin-bottom: PX2REM(40px);
    }
  }
  .footer {
    margin-top: PX2REM(40px);
  }
  .part {
    &+.part {
      margin-top: PX2REM(40px);
    }
  }
}
