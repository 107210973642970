
      ;
      $app_version: 'v1.0.0_5807321';
      $app_name: 'website';
      
@import "../../styles/utils.scss";

@include s-large {
  .Breadcrumb {
    font-size: 14px;
    color: #666;
    padding: 20px 0;
  }
}

@include s-middle {
  .Breadcrumb {
    font-size: 14px;
    color: #666;
    padding-left: 20px;
  }
}
@include s-small {
  .Breadcrumb {
    display: none;
  }
}
