
      ;
      $app_version: 'v1.0.0_5807321';
      $app_name: 'website';
      
@import "./styles/utils.scss";

* {
  margin: 0;
  padding: 0;
  -webkit-tap-highlight-color: transparent;
}

body {
  margin: 0;
  font-family: "AlibabaPuHuiTi", "Helvetica Neue", Helvetica, "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", "微软雅黑", Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a {
  color: $heading;

  &:hover {
    text-decoration: none;
    color: currentColor;
    outline: none;
  }

  &:active {
    outline: none;
  }

  &:focus {
    outline: none;
  }

  &:visited {
    outline: none;
  }
}

button {
  border: none;
  outline: none;

  &:active,
  &:focus {
    outline: none;
  }
}

p {
  margin: 0;
}

@include s-large {
  p {
    line-height: 2;
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@include s-large {
  html {}

  body {
    // max-width: $pc-max-width;
    // min-width: $mobile-max-width;
    font-size: 16px;
  }
}


@include s-small {
  ::-webkit-scrollbar {
    display: none;
    -ms-overflow-style: none;
    /* Internet Explorer 10+ */
    scrollbar-width: none;
    /* Firefox */
  }

  html {
    @include fs-rem(0, 320);
    @include fs-rem(321, 360);
    @include fs-rem(361, 375);
    @include fs-rem(376, 393);
    @include fs-rem(394, 412);
    @include fs-rem(413, 414);
    @include fs-rem(415, 480);
    @include fs-rem(481, 540);
    @include fs-rem(541, 640);
    @include fs-rem(641, 720);
    @include fs-rem(721, 750);
    @include fs-rem(751, 768);
  }

  body {
    font-size: PX2REM(32px);
  }
}

.block-link {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
}

.hidden-large {
  @include s-large {
    display: none !important;
  }
}

.hidden-small {
  @include s-small {
    display: none !important;
  }
}

