
      ;
      $app_version: 'v1.0.0_5807321';
      $app_name: 'website';
      
@import "../../../styles/utils.scss";
@import "../../../styles/snippet.scss";

.tabContainer {
  .info {
    flex: 1;

    .description {
      @extend .description;
    }
    .introduce {
      @extend .description;
      color: #333333;
    }
    .list {
      display: flex;
      @extend .description;
      color: #333333;

      .item {
        display: flex;
        align-items: center;
      }
    }

    .more {
      display: flex;
      align-items: center;
      justify-content: center;
      background: linear-gradient(180deg, #3D3A39 0%, #000000 100%);
      border-radius: 20px;
      color: #FFFFFF;

    }
  }

  .cover {

  }
}

.Section {
  @extend .bottomLine;
}
.tabContainer {

}

@include s-large {
  .tabContainer {
    display: flex;
    align-items: flex-start;

    .info {
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: 40px 50px;

      .description {
        margin-bottom: 24px;
      }

      .logo {
        margin-bottom: 25px;

        img {
          height: 36px;
        }
      }

      .introduce {
        font-size: 16px;
        margin-bottom: 0;
      }

      .list {
        display: flex;
        flex-wrap: wrap;

        .item {
          display: flex;
          align-items: flex-start;
          font-size: 16px;
          margin: 20px 20px 0 0;

          &:last-child {
            margin-right: 0;
          }

          .icon {
            width: 24px;
            height: 24px;
            margin-right: 5px;
          }
        }
      }

      .more {
        width: 120px;
        height: 40px;
        font-size: 16px;
        margin-top: 25px;
        cursor: pointer;
      }
    }

    .cover {
      width: 360px;
      flex-shrink: 0;
      margin: 20px;
    }
  }
}

@include s-small {
  .Section {
    padding: PX2REM(10px) PX2REM(10px) PX2REM(26px) !important;
  }
  .info {
    flex: 1;
    padding: 0 PX2REM(30px);
    margin-top: PX2REM(60px);

    .description {
      font-size: PX2REM(32px);
      margin-bottom: PX2REM(80px);
      line-height: PX2REM(56px);
    }

    .logo {
      height: PX2REM(72px);
      margin-bottom: PX2REM(40px);

      img {
        height: PX2REM(72px);
      }
    }

    .introduce {
      font-size: PX2REM(32px);
      line-height: PX2REM(56px);
      margin-bottom: PX2REM(40px);
    }

    .list {
      flex-direction: column;

      .item {
        font-size: PX2REM(32px);
        line-height: PX2REM(50px);
        align-items: flex-start !important;
        margin-bottom: PX2REM(20px);

        .icon {
          width: PX2REM(44px);
          height: PX2REM(44px);
          margin-right: PX2REM(15px);
          margin-top: PX2REM(6px);
        }
      }
    }

    .more {
      width: PX2REM(240px);
      height: PX2REM(80px);
      font-size: PX2REM(32px);
      margin-top: PX2REM(50px);
      margin-bottom: PX2REM(80px);
    }
  }

  .cover {
    display: none;
  }
}
