
      ;
      $app_version: 'v1.0.0_5807321';
      $app_name: 'website';
      
@import '../../styles/utils.scss';

@include s-large {
  .video {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, .6);
    z-index: 999;

    .videoBox {
      position: absolute;
      top: 45%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 50%;
      video {
        width: 100%;
      }

      .close {
        position: absolute;
        bottom: -100px;
        left: 50%;
        transform: translateX(-50%);
        display: flex;
        align-items: center;
        justify-content: center;
        width: 40px;
        height: 40px;
        border-radius: 50%;
        border: 2px solid #fff;
      }
    }
  }
}

@include s-small {
  .video {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, .6);
    z-index: 999;

    .videoBox {
      position: absolute;
      top: 45%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 100%;
      video {
        width: 100%;
      }
      .close {
        position: absolute;
        bottom: PX2REM(-100px);
        left: 50%;
        transform: translateX(-50%);
        display: flex;
        align-items: center;
        justify-content: center;
        width: PX2REM(60px);
        height: PX2REM(60px);
        border-radius: 50%;
        border: 2px solid #fff;
      }
    }
  }
}
