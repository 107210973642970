
      ;
      $app_version: 'v1.0.0_5807321';
      $app_name: 'website';
      
@import "../../styles/utils.scss";

.BlogDetail {
  --title-color: #333;
  --description-color: #666;
}

.description {
  color: var(--description-color);

  @include s-large {
    font-size: 14px;
    line-height: 28px;
  }
  @include s-small {
    font-size: PX2REM(28px);
    line-height: PX2REM(40px);
  }
}

.sectionTitle {
  color: var(--title-color);
  font-weight: bold;

  @include s-large {
    font-size: 16px;
    line-height: 28px;
  }
  @include s-small {
    font-size: PX2REM(36px);
    line-height: PX2REM(48px);
  }
}


.BlogDetail {
  .pageTitle {
    font-weight: bolder;
  }

  .pageTips {
    @extend .description;
  }

  .sectionTitle {
    @extend .sectionTitle;
  }

  .section {
  }

  .sectionMain {
    .item {
      position: relative;
      display: flex;
      flex-direction: column;
      color: var(--description-color);

      &:before {
        content: '';
        position: absolute;
        left: 0;
        top: 8px;
        width: 8px;
        height: 8px;
        border-radius: 50%;
        background: #d8d8d8;
      }

      .subTitle {
        color: var(--title-color);
        font-weight: bold;
      }
      .text {
        a {
          color: var(--description-color);
          text-decoration: underline;
        }
      }
    }
  }

  .title {
    font-weight: bold;
    text-decoration: underline;
  }
}

@include s-large {
  .BlogDetail {
    .pageTitle {
      font-weight: bolder;
    }

    .pageTips {
    }

    .sectionTitle {
      margin-bottom: 20px;
    }

    .section {
      margin-bottom: 40px;
    }

    .sectionMain {
      .item {
        padding-left: 16px;
        margin-bottom: 20px;

        &:before {
          content: '';
          position: absolute;
          left: 0;
          top: 8px;
          width: 8px;
          height: 8px;
          border-radius: 50%;
        }

        .subTitle {
          font-size: 14px;
          line-height: 24px;
          margin-bottom: 10px;
        }
        .text {
          font-size: 14px;
        }
      }
    }

    .title {
      font-weight: bold;
      text-decoration: underline;
    }
  }
  .main {
    max-width: 1200px;
    margin: 0 auto;

    .pageTitle {
      font-size: 20px;
      color: #222222;
      line-height: 30px;
      margin-bottom: 24px;
      margin-top: 20px;
    }

    .pageTips {
      margin-bottom: 40px;
    }

    .sections {

    }
  }
}

@include s-small {
  .BlogDetail {
    .main {
      padding: 0 PX2REM(38px);
    }

    .pageTitle {
      font-size: PX2REM(40px);
      line-height: PX2REM(40px);
      margin-bottom: PX2REM(40px);
      margin-top: PX2REM(80px);
    }

    .sectionTitle {
      margin-bottom: PX2REM(40px);
      margin-top: PX2REM(100px);
      font-size: PX2REM(36px);
      line-height: PX2REM(48px);
    }

    .section {
      margin-bottom: PX2REM(100px);
    }

    .sectionMain {
      .item {
        padding-left: PX2REM(36px);
        margin-bottom: PX2REM(40px);

        &:before {
          content: '';
          position: absolute;
          left: 0;
          top: PX2REM(14px);
          width: PX2REM(16px);
          height: PX2REM(16px);
          border-radius: 50%;
        }

        .subTitle {
          line-height: PX2REM(48px);
          margin-bottom: PX2REM(20px);
          font-size: PX2REM(32px);
        }
        .text {
          font-size: PX2REM(32px);
          line-height: PX2REM(48px);
        }
      }
    }

    .title {
      font-weight: bold;
      text-decoration: underline;
    }
  }
}
