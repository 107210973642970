
      ;
      $app_version: 'v1.0.0_5807321';
      $app_name: 'website';
      
@import "../../styles/utils.scss";

.Global {
  --theme: #F9E72C;
  --theme-gradient: linear-gradient(180deg, #464646 0%, #222222 100%);
  --banner-title-color: #fff;
  --large-logo-height: 28px;
}


.main {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;

  color: #333333;
}

@include language-en {
  @include s-large {
    .cities {
      display: inline;
      .city {
        display: inline;
      }
      .city:not(:last-child) {
        &:after {
          content: ',' !important;
        }
      }
    }
  }
}
@include s-large {
  .Section {
    margin-bottom: 80px;
  }
  .main {
    font-size: 16px;

    .info {
      margin-top: 24px;
      width: 600px;
      margin-right: 50px;

      .regionItem {
        display: flex;
        margin-bottom: 10px;
        flex-wrap: wrap;
        word-break: break-word;
        color: #666666;
      }
    }

    .cover {
      flex: 1;
      width: 0;
    }
  }
}

@include s-small {
  .main {
    flex-direction: column;

    .info {
      margin-bottom: PX2REM(60px);

      .regionItem {
        margin-bottom: PX2REM(20px);

        .areaName {
          display: inline;
          font-size: PX2REM(28px);
          font-weight: bold;
          margin-bottom: PX2REM(10px);
          flex-shrink: 0;
          margin-right: PX2REM(20px);

          &:after {
            content: ':';
          }
        }

        .cities {
          display: inline;

          .city {
            display: inline;
            margin-right: PX2REM(15px);

            &:not(:last-child) {
              &:after {
                content: '、';
              }
            }
          }
        }
      }
    }

    .cover {
      margin-bottom: PX2REM(40px);
    }
  }

  .global {
    display: flex;
    justify-content: center;
    margin-right: 0;
    padding: 0 PX2REM(40px) PX2REM(40px);

    img {
      width: 100%;
    }
  }
}
