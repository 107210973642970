
      ;
      $app_version: 'v1.0.0_5807321';
      $app_name: 'website';
      
@import "../../../styles/utils.scss";

.Banner {
  img {
    width: 100%;
  }
}

@include s-large {
  .Banner {
    display: flex;
    justify-content: center;
    width: 100%;

    .main {
      max-width: 1920px;
      width: 100%;
      height: 180px;
      background: url(ossImg('/esg/overall-banner.png')) no-repeat center center;
    }
  }
}

@include s-small {
  .Banner {
    display: flex;
    justify-content: center;
    width: 100%;

    .main {
      width: 100%;
      height: PX2REM(360px);
      background: url(ossImg('/esg/esg-m-banner.png')) no-repeat center center;
    }
  }
}
