
      ;
      $app_version: 'v1.0.0_5807321';
      $app_name: 'website';
      
@import "../../../styles/utils.scss";

@include s-large {
  .main {
    display: flex;
    align-items: flex-start;

    .info {
      width: 670px;
      margin-right: 50px;
      flex-shrink: 0;

      .title {
        font-size: 24px;
        color: #333333;
        line-height: 32px;
        margin-bottom: 24px;
      }

      .description {
        font-size: 16px;
        color: #666666;
        line-height: 28px;
        margin-bottom: 20px;
      }


    }
    .right {
      display: flex;
      flex-direction: column;
      align-items: flex-end;

      .swiper {
        width: 480px;
        height: 280px;
      }
    }
    .cover {
      flex: 1 1 auto;
      height: 280px;
    }

    .dots {
      display: flex;
      margin-bottom: 45px;
      margin-top: 20px;

      .dot {
        width: 10px;
        height: 10px;
        border-radius: 50%;
        background: #E5E5E5;
        margin-right: 6px;

        &.active {
          background: var(--theme, #F9E72C);
        }
      }
    }
  }
}

@include s-small {
  .Section {
    margin-bottom: 0;
  }
  .main {
    display: flex;
    align-items: flex-start;
    flex-direction: column;

    .info {
      flex: 1;

      .title {
        font-size: PX2REM(36px);
        color: #333333;
        line-height: PX2REM(54px);
        margin-bottom: PX2REM(20px);
        font-weight: bold;
      }

      .subTitle {
        font-size: PX2REM(32px);
        color: #333333;
        line-height: PX2REM(48px);
        margin-bottom: PX2REM(20px);
        font-weight: bold;
      }

      .description {
        font-size: PX2REM(32px);
        color: #666666;
        line-height: PX2REM(48px);
        margin-bottom: PX2REM(40px);
      }
    }

    .dots {
      display: flex;

      .dot {
        width: PX2REM(16px);
        height: PX2REM(16px);
        border-radius: 50%;
        background: #E5E5E5;
        margin-right: PX2REM(10px);

        &.active {
          background: var(--theme, #F9E72C);
        }
      }
    }

    .cover {
      width: 100%;
      margin-bottom: PX2REM(20px);
    }
    .right {
      width: 100%;
    }
  }
}
