
      ;
      $app_version: 'v1.0.0_5807321';
      $app_name: 'website';
      
@import "../../../styles/utils.scss";

.performance {
}

.container {

  background: linear-gradient(180deg, #FDF557 0%, #F9E52C 100%);

  .main {
    display: flex;
    align-items: center;
    justify-content: center;
  }

}

@include s-large {
  .performance {
    margin-top: 62px;

    .header {
      width: $pc-max-width;
      font-size: 20px;
      font-weight: bold;
      margin: 0 auto 24px;
    }
  }
  .container {
    width: auto;
    height: 240px;

    .main {
      width: $pc-max-width;
      margin: 0 auto;
      height: 240px;

      .item {
        flex: 1;
        color: #333333;
        justify-content: center;

        .firstline {
          margin-bottom: 10px;
          padding-left: 70px;

          .keyword {
            font-size: 34px;
          }
        }

        .lastline {
          padding-left: 70px;

          .keyword {
            font-size: 16px;
          }
        }
      }
    }
  }
}
@include s-middle {
  .performance {
    .header {
      padding-left: $middle-padding;
    }
  }
}

@include s-small {
  .performance {
    margin-top: 62px;

    .header {
      font-size: PX2REM(40px);
      font-weight: bold;
      margin: 0 auto PX2REM(48px);
    }
  }
  .container {
    padding: PX2REM(80px) 0 PX2REM(80px) PX2REM(67px);

    .main {
      flex-direction: column;
      margin: 0 auto;
      align-items: flex-start;

      .item {
        flex: 1;
        color: #333333;
        justify-content: center;
        margin-bottom: PX2REM(80px);

        .firstline {
          margin-bottom: PX2REM(20px);

          .keyword {
            font-size: PX2REM(68px);
          }
        }

        .lastline {
          .keyword {
            font-size: PX2REM(32px);
          }
        }
      }
    }
  }
}
