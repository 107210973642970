
      ;
      $app_version: 'v1.0.0_5807321';
      $app_name: 'website';
      
@import "../../../../styles/utils.scss";

.main {
  .img {

  }
}

@include s-large {
  .main {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;

    .img {
      width: 270px;
      height: 200px;

      img {
        width: 100%;
        height: 100%;
      }
    }
  }
}

@include s-middle {
  .main {
    .img {
      width: 270px;
      max-width: 22vw;
      height: 200px;

      img {
        width: 100%;
        height: 100%;
      }
    }
  }
}

@include s-small {
  .main {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    margin-bottom: PX2REM(40px);

    .img {
      display: flex;
      align-items: center;
      font-size: PX2REM(32px);
      color: #666666;
    }
  }
}
