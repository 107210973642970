
      ;
      $app_version: 'v1.0.0_5807321';
      $app_name: 'website';
      
@import "../../styles/utils.scss";

.Paipai {
  --theme: #D3E96A;
  --theme-gradient: linear-gradient(180deg, #AFF500 0%, #ABF000 100%);
  --swiper-dot-active-color: var(--theme, #F9E72C);
}

.services {
  display: flex;
  align-items: center;

  .serviceItem {
    text-align: center;
    color: #333333;

    .title {
      font-weight: bold;
    }

    .subTitle {

    }
  }
}

.main {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;

  .info {
    .title {
      color: #333333;
      font-weight: bold;
    }

    .description {
      color: #666666;
    }
  }
}

.advantages {
  display: flex;
  align-items: center;

  .advantageItem {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    .title {
      font-weight: bold;
      color: #333333;
    }

    .subTitle {
      color: #666666;
    }
  }
}

@include language-en {
  .Paipai {
    .services {
      .title {
        font-size: 30px;
      }
    }
  }
}

@include s-large {
  .services {
    height: 240px;

    .serviceItem {
      flex: 1;

      .title {
        font-size: 32px;
        line-height: 32px;
        margin-bottom: 20px;
      }

      .subTitle {
        font-size: 16px;
        color: #333333;
        line-height: 28px;
      }
    }
  }
  .main {
    .info {
      flex: 1 1 auto;

      .title {
        margin-bottom: 10px;
      }

      .description {
        color: #666666;
        line-height: 28px;
        display: flex;
      }
    }

    .cover {
      flex: 0 0 440px;
      width: 440px;
      height: 280px;
      margin-right: 50px;
    }
  }
  .advantages {
    display: flex;
    align-items: flex-start;
    margin-bottom: 60px;

    .advantageItem {
      flex: 1;
      text-align: center;

      img {
        width: 100px;
        height: 100px;
        margin-bottom: 20px;
      }

      .title {
        font-size: 16px;
        line-height: 28px;
        margin-bottom: 8px;
      }

      .subTitle {
        font-size: 14px;
        line-height: 22px;
        margin-bottom: 6px;
        padding: 0 10px;
      }
    }
  }
}

@include s-small {
  .services {
    flex-direction: column;
    align-items: flex-start;
    padding: PX2REM(60px) 0 PX2REM(60px) PX2REM(80px);

    .serviceItem {
      flex: 1;
      text-align: left;

      &:not(:last-child) {
        margin-bottom: PX2REM(60px);
      }

      .title {
        font-size: PX2REM(40px);
        line-height: PX2REM(40px);
        margin-bottom: PX2REM(20px);
      }

      .subTitle {
        font-size: PX2REM(32px);
        color: #333333;
        line-height: 28px;
      }
    }
  }

  .main {
    flex-direction: column-reverse;

    .info {
      flex: 1 1 auto;
      margin-bottom: PX2REM(40px);

      .title {
        font-size: PX2REM(32px);
        font-weight: bold;
      }

      .description {
        font-size: PX2REM(32px);
        line-height: PX2REM(48px);
      }
    }

    .cover {
      width: 100%;
    }
  }

  .advantages {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    background: #F6FBE7;
    padding: PX2REM(40px);
    margin: 0 PX2REM(40px) PX2REM(60px) PX2REM(40px);

    .advantageItem {
      flex: 1;
      flex-direction: row;
      justify-content: flex-start;
      margin-bottom: PX2REM(60px);

      img {
        width: PX2REM(120px);
        height: PX2REM(120px);
        margin-right: PX2REM(40px);
      }

      .box {
        padding-top: 12px;

        .title {
          font-size: PX2REM(32px);
          line-height: PX2REM(48px);
          margin-bottom: PX2REM(8px);
        }

        .subTitle {
          font-size: PX2REM(28px);
          line-height: PX2REM(40px);
        }
      }

    }
  }
}
