
      ;
      $app_version: 'v1.0.0_5807321';
      $app_name: 'website';
      
@import "../../../../styles/utils.scss";

.main {
  display: flex;
  align-items: flex-start;

  .left {
    color: #666666;
    font-size: 16px;
  }

  .right {
    background: #F5F6FA;
  }

  .notes {
    margin-top: 1em;
    color: #999999;
  }
}

@include s-large {
  .main {
    .left {
      font-size: 16px;
      max-width: 500px;
      margin-right: 50px;
    }

    .right {
      padding: 40px;
      flex: 1;
    }

    .notes {
      font-size: 12px;
    }
  }
}

@include s-middle {
  .main {
    display: flex;

    .left {
      max-width: 35vw;
    }

    .right {
      flex: 1;
    }
  }
}

@include s-small {
  .main {
    display: flex;
    flex-direction: column;

    .left {
      font-size: PX2REM(32px);
      margin-bottom: PX2REM(40px);
    }

    .right {
      background: transparent;

      .units {}
    }

    .notes {
      font-size: PX2REM(24px);
    }
  }
}

@include language-en {
  .Automation {
    .unitItem {
      flex: 1 1 50%;

      &:nth-last-child(2),
      &:nth-last-child(1) {
        margin-bottom: 0;
      }

      &:nth-child(n+3) {
        margin-top: 30px;
      }

      >.name {
        margin-bottom: 10px;
      }
    }
  }
}