
      ;
      $app_version: 'v1.0.0_5807321';
      $app_name: 'website';
      
@import '../../styles/utils.scss';

.divider {
  position: relative;
  background: #F9E72C;
}

@include s-large {
  .divider {
    height: 8px;
    border-bottom-right-radius: 4px;
    border-bottom-left-radius: 4px;

    &::after {
      position: absolute;
      top: 2px;
      left: 0;
      right: 0;
      height: 2px;
      content: '';
      background: #fff;
    }
  }
}

@include s-small {
  .divider {
    height: PX2REM(8px);
    margin: 0 PX2REM(40px);
    box-sizing: border-box;

    &::after {
      position: absolute;
      top: PX2REM(2px);
      left: 0;
      right: 0;
      height: PX2REM(2px);
      content: '';
      display: inline-block;
      background: #fff;
    }
  }
}
