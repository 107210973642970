
      ;
      $app_version: 'v1.0.0_5807321';
      $app_name: 'website';
      
@import "../../styles/utils.scss";

.box {
  background: $body-bg;
  border-top: #D8D8D8 1px solid;
}

.friendLink {
  .groups {

    .groupTitle {
      font-weight: bold;
      color: #333;
    }

    .groupLink {
      line-height: 2;
      color: #666;
    }
  }
}

.copyRights {
  width: 100%;
  background: #3D3A39;
  border-top: 1px solid #979797;

  .copyRightLink {
    color: #C8C8C8;
  }

  .icon {
    height: 1.2em;
    vertical-align: text-top;
  }
}

@include s-large {
  .box {
    padding-top: 55px;
  }

  .friendLink {
    margin: 0 -120px;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;

    @media screen and (max-width: 1500px) {
      margin: 0;
    }

    .logo {
      width: 152px;
      height: 54px;
      flex: 0 0 auto;
    }

    .groups {
      margin-left: 60px;
      flex: 1 1 auto;
      max-width: 1000px;
      display: flex;
      justify-content: space-between;

      .group {
        margin-right: 60px;

        &:last-child {
          margin-right: 0;
        }
      }

      .groupItem {
        white-space: nowrap;
      }

      .paragraph {
        white-space: initial;
      }

      .groupTitle {
        font-size: 14px;
        line-height: 2;
        display: block;
      }

      .groupLink {
        font-size: 14px;
        display: block;
      }
    }
  }

  .copyRights {
    margin-top: 49px;
    padding: 20px 0;
    text-align: center;
    font-size: 12px;

    .copyRightLink {
      color: #C8C8C8;
      line-height: 2;
      display: inline-block;

      &+.copyRightLink {
        margin-left: 0.5em;
      }

      &:nth-child(2) {
        margin-left: 3em;
      }
    }
  }

  .govLinks {
    font-size: 12px;

    .copyRightLink {
      &+.copyRightLink {
        margin-left: 1em;

        &::before {
          content: '|';
          display: inline-block;
          margin-right: 1em;
        }
      }
    }
  }
}

@include s-small {
  .friendLink {
    padding: PX2REM(80px) PX2REM(40px) PX2REM(60px);

    .logo {
      width: PX2REM(304px);
      height: PX2REM(108px);
      flex: 0 0 auto;
    }

    .groups {
      margin-top: PX2REM(80px);
      flex: 1 1 auto;
      max-width: 1000px;
      font-size: PX2REM(28px);

      .group {
        margin-bottom: PX2REM(30px);
      }

      .groupTitle {
        line-height: 3;
        font-size: PX2REM(30px);
        display: block;
      }

      .groupLinks {
        display: block;
      }

      .groupLink {
        line-height: 2.8;
        color: #666;
        margin-left: 1em;

        &:first-child {
          margin-left: 0;
        }

        &.block {
          display: block;
          margin-left: 0;
        }
      }
    }
  }

  .copyRights {
    padding: PX2REM(48px) PX2REM(40px);

    p {
      display: flex;
      flex-wrap: wrap;
      align-items: center;

      &:first-child {
        margin-right: 0;
      }
    }

    .copyRightLink {
      color: #C8C8C8;
      font-size: PX2REM(24px);
      display: block;
      line-height: 2.2;
      margin-right: PX2REM(16px);
    }
  }
}
