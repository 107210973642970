
      ;
      $app_version: 'v1.0.0_5807321';
      $app_name: 'website';
      
@import '../../../styles/utils.scss';


@include s-large {
  .grid {
    display: grid;
    grid-row-gap: 36px;
    grid-column-gap: 5%;
    margin-top: 24px;
    grid-template-columns: repeat(3, 30%);

    .image {
      position: relative;
      width: 100%;
      height: 0;
      padding: 0;
      padding-bottom: 55.55%;

      img {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
      }
    }

    span {
      display: block;
      margin-top: 10px;
      font-size: 16px;
      font-weight: bold;
    }

    p {
      margin-top: 4px;
      color: #666;
    }
  }
}

@include s-small {
  .grid {

    .image {
      margin-top: PX2REM(48px);
      height: PX2REM(372px);

      img {
        width: auto;
        height: 100%;
        max-height: 100%;
        object-fit: cover;
      }
    }

    span {
      margin-top: PX2REM(24px);
      font-size: PX2REM(32px);
      font-weight: bold;
      display: block;
    }

    p {
      margin-top: PX2REM(14px);
      font-size: PX2REM(32px);
      color: #666;
    }
  }
}
