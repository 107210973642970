
      ;
      $app_version: 'v1.0.0_5807321';
      $app_name: 'website';
      
@import '../../../styles/utils.scss';

.Series {
  .title {
    color: #222222;
    font-weight: bold;
  }
}

@include s-large {
  .Series {
    .title {
      font-size: 20px;
    }

    .articles {
      margin-top: 24px;
    }
  }
}

@include s-small {
  .Series {
    margin: 0 $mobile-horizontal-padding;
    .title {
      font-size: 20px;
    }

    .articles {
      margin-top: PX2REM(30px);
    }
  }
}