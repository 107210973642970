
      ;
      $app_version: 'v1.0.0_5807321';
      $app_name: 'website';
      
@import "../../../../styles/utils.scss";


.main {
  background: var(--theme-gradient);

  .title {
    font-weight: bold;
    color: #333333;
  }

  .subTitle {
    color: #333333;
  }
}

@include s-large {
  .main {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    padding: 40px 0;

    .separate {
      align-self: center;
      width: 53px;
      height: 40px;
    }

    .left, .right {
      max-width: 470px
    }

    .title {
      font-size: 20px;
      line-height: 30px;
      margin-bottom: 24px;

    }

    .subTitle {
      font-size: 16px;
      line-height: 28px;
      margin-bottom: 40px;
    }

    .icons {
      display: flex;
      align-items: center;

      .icon {
        width: 80px;
        height: 80px;
        margin-right: 50px;
      }
    }
  }
}

@include language-en {
  @include s-large {
    .subTitle {
      height: 84px;
    }
  }
}

@include s-small {
  .main {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-direction: column;
    padding: PX2REM(40px);

    .separate {
      align-self: center;
      width: PX2REM(52px);
      height: PX2REM(40px);
      transform: rotate(90deg);
      margin: PX2REM(60px) 0;
    }

    .title {
      font-size: PX2REM(32px);
      line-height: 30px;
      margin-bottom: PX2REM(40px);

    }

    .subTitle {
      font-size: PX2REM(32px);
      margin-bottom: PX2REM(40px);
      line-height: PX2REM(48px);
    }

    .icons {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .icon {
        flex: 1;
        display: flex;
        justify-content: center;
        img {
          width: PX2REM(120px);
          height: PX2REM(120px);
        }
      }
    }
  }
}
