
      ;
      $app_version: 'v1.0.0_5807321';
      $app_name: 'website';
      
@import "../../../styles/utils.scss";

.PublicWelfare {
  @extend .bottomLine;

}

@include s-large {
  .PublicWelfare {
    width: $pc-max-width;
    margin: 80px auto;
    padding-bottom: 24px;

    .main {
      display: flex;
      align-items: center;
      padding: 0 20px;

      .info {
        flex: 1;
        margin-right: 50px;

        .logo {
          height: 60px;
          margin-bottom: 24px;
        }

        .title {
          font-size: 20px;
          color: #333333;
          font-weight: bold;
          line-height: 30px;
        }

        .description {
          font-size: 16px;
          color: #666666;
          line-height: 28px;
          margin-bottom: 20px;
        }

        .dots {
          display: flex;
          margin-bottom: 26px;
          margin-top: 40px;
        }

        .more {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 120px;
          height: 40px;
          background: linear-gradient(180deg, #3D3A39 0%, #000000 100%);
          border-radius: 20px;
          color: #FFFFFF;

        }
      }

      .cover {
        width: 360px;
        height: 360px;
      }
    }
  }
}

@include s-small {
  .PublicWelfare {
    margin: PX2REM(160px) auto;
    padding: 0 PX2REM(30px) PX2REM(80px) PX2REM(30px);

    .main {
      display: flex;
      align-items: center;

      .info {
        flex: 1;

        .logo {
          height: PX2REM(120px);
          margin-bottom: PX2REM(48px);
        }

        .title {
          font-size: PX2REM(40px);
          color: #333333;
          line-height: PX2REM(60px);
          margin-bottom: PX2REM(24px);
        }

        .description {
          font-size: PX2REM(32px);
          color: #666;
          line-height: PX2REM(56px);
          margin-bottom: PX2REM(40px);
        }

        .dots {
          display: flex;
          margin-bottom: PX2REM(80px);
        }

        .more {
          display: flex;
          align-items: center;
          justify-content: center;
          width: PX2REM(240px);
          height: PX2REM(80px);
          background: linear-gradient(180deg, #3D3A39 0%, #000000 100%);
          border-radius: PX2REM(40px);
          color: #FFFFFF;
        }
      }

      .cover {
       display: none;
      }
    }
  }
}
