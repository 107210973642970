
      ;
      $app_version: 'v1.0.0_5807321';
      $app_name: 'website';
      
@import "../../../styles/utils.scss";

.Privacy {
  display: flex;

  .description {
    color: #666666;
  }
}

@include s-large {
  .Section {
    margin-bottom: 60px;
  }
  .Privacy {
    display: flex;

    .cover {
      margin-right: 50px;
      width: 480px;
      height: 280px;

      img {
        width: 100%;
        height: 100%;
      }
    }

    .description {
      flex: 1;
      font-size: 16px;
    }

    .iconItems {
      .iconItem {
        min-width: 0;
        margin-right: 60px;
        margin-top: 20px;

        .icon {
          width: 28px;
          height: 28px;
        }

        .title {
          font-size: 16px;
          color: #666;
          font-weight: normal;
        }
      }
    }
  }

}

@include s-middle {

}

@include s-small {
  .Section {
    margin-bottom: 0;
  }
  .Privacy {
    flex-direction: column-reverse;

    .cover {
      width: auto;
      height: PX2REM(420px);
      margin-top: PX2REM(40px);

      img {
        width: 100%;
        height: 100%;
      }
    }

    .description {
      flex: 1;
      font-size: 16px;
    }

    .iconItems {
      padding: PX2REM(40px);
      margin-top: PX2REM(40px);
      background: #FEFBDF;

      .iconItem {
        margin-top: PX2REM(40px);

        &:first-child {
          margin-top: 0;
        }

        .icon {
          width: PX2REM(64px);
          height: PX2REM(64px);
        }

        .title {
          font-size: PX2REM(32px);
          color: #333;
          font-weight: normal;
        }
      }
    }
  }
}
