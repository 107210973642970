
      ;
      $app_version: 'v1.0.0_5807321';
      $app_name: 'website';
      
@import '../../styles/utils.scss';
@import '../../styles/variable.scss';

@include s-large {
  .Index {
    padding-bottom: 60px;
  }

  .breadcrumb {
    padding-bottom: 0;
  }

  .banner {
    height: 180px;
    img {
      width: auto;
      height: 100%;
      max-height: 100%;
      object-fit: cover;
    }
  }
  .sectionA {
    max-width: $pc-max-width;
    margin: 0 auto;
  }

  .sectionB {
    margin-top: 24px;
    padding: 8px 0 40px;
    background: #F5F6FA;

    .box {
      max-width: 1220px;
      margin: 0 auto;
      padding: 0 20px;
    }

    .image {
      width: 480px;
      height: 280px;
    }
  }

  .title {
    font-size: 20px;
    margin-top: 60px;
    display: block;
  }

  .subTitle {
    font-size: 16px;
    color: #333;
    margin-top: 24px;
  }

  .content {
    margin-top: 20px;
    line-height: 1.75;
  }

  .divider {
    margin-top: 16px;
  }

  .build {
    margin-top: 24px;
  }

  .care-grid-title {
    font-size: 14px;
  }

  .care-grid-content {
    font-size: 14px;
    color: #999;
  }
}

@include s-small {
  .Index {
    padding-bottom: PX2REM(100px);
  }
  .banner {
    height: PX2REM(360px);
    img {
      width: auto;
      height: 100%;
      max-height: 100%;
      object-fit: cover;
    }
  }
  .sectionA {
    margin-top: PX2REM(80px);
  }

  .sectionB {
    padding: PX2REM(40px) 0 PX2REM(76px);
    margin-top: PX2REM(40px);
    background: #F5F6FA;

    .image {
      width: PX2REM(670px);
      height: PX2REM(390px);
      margin-top: PX2REM(40px);
    }
  }

  .joinImg {
    height: PX2REM(370);
    margin-top: PX2REM(40px);
    display: flex;
    justify-content: center;
  }

  .title {
    display: block;
    font-size: PX2REM(40px);
    margin-top: PX2REM(100px);
    margin-left: PX2REM(40px);
  }

  .subTitle {
    display: block;
    font-size: PX2REM(32px);
    margin-top: PX2REM(60px);
    color: #333;
  }

  .content {
    font-size: PX2REM(32px);
    margin-top: PX2REM(20px);
  }

  .divider {
    margin: PX2REM(40px) PX2REM(40px) 0;
  }

  .article {
    margin-top: PX2REM(100px);
  }

  .build {
    width: PX2REM(670px);
    height: PX2REM(257px);
    margin: PX2REM(40px) auto 0;
    display: block;
  }
}