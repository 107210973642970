
      ;
      $app_version: 'v1.0.0_5807321';
      $app_name: 'website';
      
@import '../../../styles/utils.scss';

.Article {
  display: flex;
  align-items: flex-start;

  .avatar {
    position: relative;

    img {
      vertical-align: bottom;
    }
  }

  .playBtn {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .detail {
    .title {
      color: #222222;
      font-weight: bold;
    }

    .createdOn,
    .content {
      color: #666666;
    }
  }
}

@include s-large {
  .Article {
    &+.Article {
      margin-top: 20px;
    }

    .avatar {
      flex: 0 0 360px;
    }

    .detail {
      margin-left: 50px;
      flex: 1 1 auto;

      .title,
      .createdOn,
      .content {
        font-size: 16px;
      }

      .createdOn {
        margin-top: 20px;
      }
    }
  }
}


@include s-small {
  .Article {
    flex-direction: column;
    align-items: stretch;

    &+.Article {
      margin-top: PX2REM(40px);
    }

    .avatar {
      margin-top: PX2REM(40px);
      order: 2;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .detail {
      order: 1;

      .title,
      .createdOn,
      .content {
        font-size: PX2REM(32px);
      }

      .createdOn {
        margin-top: PX2REM(12px);
        margin-bottom: PX2REM(30px);
      }

      .content {}

    }
  }
}