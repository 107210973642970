
      ;
      $app_version: 'v1.0.0_5807321';
      $app_name: 'website';
      
@import "../../styles/utils.scss";
@import "../../styles/snippet.scss";

.Blog {

  .main {
    margin-bottom: 60px;
    margin-top: -40px;

    .mainContainer {
      @extend .bottomLine;
    }

    .header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      background: linear-gradient(180deg, #FFFFFF 0%, #F0F0F0 100%);
      box-shadow: 0px 1px 0px 0px rgba(0, 0, 0, 0.05);

      .icon {
        flex-shrink: 0;
        width: 16px;
        height: 16px;
        background: url('../../assets/icon-add.png') no-repeat center/cover;
        cursor: pointer;

        &.reduce {
          background: url('../../assets/icon-reduce.png') no-repeat center;
        }
      }
    }

    .title {
      font-weight: bold;
    }

    .section {
      .headerText {
        @extend .subtitle;
      }

      .description {
        color: #666;
      }
    }
  }
}

@include s-large {
  .main {
    max-width: 1200px;
    margin: 0 auto;

    .pageTitle {
      font-size: 20px;
      color: #222222;
      line-height: 30px;
      margin-bottom: 24px;
      margin-top: 20px;
    }

    .header {
      width: auto;
      height: 80px;
      font-size: 20px;
      padding: 0 50px;
      border-top: 1px solid rgba(0, 0, 0, 0.05);
      cursor: pointer;

      .title {
        font-weight: normal;
      }
    }

    .panelBox {
      overflow: hidden;
      padding: 40px 50px 20px;
    }

    .panel {
      display: flex;
      align-items: center;
    }

    .list {
      .item {
        margin-bottom: 20px;

        .title {
          font-size: 16px;
          color: #333333;
          line-height: 28px;
          text-decoration: underline;
        }

        .description {
          font-size: 14px;
          color: #666666;
          line-height: 28px;
        }

        .date {
          font-size: 14px;
          color: #999999;
          line-height: 28px;
        }
      }
    }
  }
}

@include s-small {
  .Blog {
    .main {
      padding: 0 PX2REM(40px);

      .header {
        width: auto;
        min-height: PX2REM(160px);
        font-size: PX2REM(32px);
        padding: PX2REM(30px);
        border-top: 1px solid rgba(0, 0, 0, 0.05);

        .title {
          margin-right: PX2REM(20px);
        }

        .iconBox {
          flex-shrink: 0;
          display: flex;
          justify-content: flex-end;
          width: PX2REM(50px);
        }
      }

      .panelBox {
        overflow: hidden;
      }

      .panel {
        display: flex;
      }
    }

    .pageTitle {
      font-size: 20px;
      color: #222222;
      line-height: 30px;
      margin-bottom: PX2REM(60px);
      margin-top: PX2REM(60px);
    }

    .list {

      .item {
        margin: PX2REM(40px) 0;

        .title {
          font-size: PX2REM(32px);
          color: #333333;
          line-height: PX2REM(56px);
        }

        .description {
          font-size: PX2REM(28px);
          color: #666666;
          line-height: PX2REM(56px);
        }

        .date {
          font-size: PX2REM(28px);
          color: #999999;
          line-height: PX2REM(56px);
        }
      }
    }
  }
}
