
      ;
      $app_version: 'v1.0.0_5807321';
      $app_name: 'website';
      
@import "../../../styles/utils.scss";

.About {
  display: flex;
  align-items: flex-start;

  .info {
    .h1 {
      color: #222222;
      font-weight: bolder;
    }

    .h3 {
      color: #333;
      font-weight: bold;

      .icon {
        flex-shrink: 0;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

    .subTitle {
      color: #666666;
    }

    .description {
      color: #999999;
    }

    .icons {
      display: flex;
      align-items: center;

      .item {
        color: #666666;
      }
    }
  }
}

@include s-large {
  .main {
    padding-bottom: 24px;
    margin-bottom: 60px;
    margin-top: 0;
  }
  .About {
    width: $pc-max-width;
    margin: 0 auto;

    .info {
      flex: 1;
      margin-right: 50px;

      .section {
        margin-bottom: 40px;

        &:last-child {
          margin-bottom: 0;
        }

        .h1 {
          font-size: 20px;
          margin-bottom: 24px;
        }

        .h3 {
          display: flex;
          align-items: center;
          position: relative;
          font-size: 16px;
          line-height: 28px;

          .icon {
            flex-shrink: 0;
            width: 50px;
            position: absolute;
            transform: translateX(-100%);
          }
        }

        .subTitle {
          font-size: 16px;

          br {
            display: none;
          }
        }

        .description {
          font-size: 16px;
          line-height: 28px;
        }

        .icons {
          margin: 20px 0;

          .item {
            display: flex;
            align-items: center;
            font-size: 16px;
            line-height: 22px;
            margin-right: 40px;

            img {
              width: 28px;
              height: 28px;
              margin-right: 8px;
            }
          }
        }
      }
    }

    .cover {
      width: 360px;
      height: 400px;
    }
  }
}

@include s-small {
  .About {
    flex-direction: column;

    .info {
      flex: 1;

      .section {
        margin-bottom: PX2REM(40px);

        .h1 {
          font-size: PX2REM(40px);
          margin-bottom: PX2REM(40px);
        }

        .h3 {
          display: flex;
          align-items: flex-start;
          flex-direction: column;
          position: relative;
          font-size: PX2REM(32px);
          line-height: PX2REM(48px);
          margin-bottom: PX2REM(20px);

          .icon {
            flex-shrink: 0;
            width: PX2REM(44px);
            height: PX2REM(32px);
            margin-bottom: PX2REM(40px);
            position: relative;
            align-self: center;

            &:before, &:after {
              content: '';
              width: PX2REM(16px);
              height: PX2REM(16px);
            }
          }
        }

        .subTitle {
          font-size: PX2REM(32px);
          line-height: PX2REM(48px);
          margin-bottom: PX2REM(20px);
        }

        .description {
          font-size: PX2REM(28px);
          line-height: PX2REM(40px);
        }

        .icons {
          margin: 20px 0;
          display: flex;
          align-items: center;
          justify-content: space-between;
          width: 100%;
          height: PX2REM(80px);
          background: #FEFBDF;
          border-radius: PX2REM(40px);
          padding: 0 PX2REM(24px);

          &.splitHorizontal {
            flex-wrap: wrap;
            justify-content: flex-start;
            background: transparent;
            height: auto;

            .item {
              flex: 0 0 50%;
              margin-right: 0;
              justify-content: flex-start;
              margin-bottom: PX2REM(30px);
            }
          }

          &.line {
            flex-wrap: wrap;
            justify-content: flex-start;
            background: transparent;
            height: auto;
            padding: 0;

            .item {
              flex: 0 0 100%;
              margin-right: 0;
              justify-content: flex-start;
              margin-bottom: PX2REM(30px);
            }
          }

          .item {
            display: flex;
            justify-content: center;
            font-size: 16px;

            img {
              width: 28px;
              height: 28px;
              margin-right: 8px;
            }
          }
        }
      }
    }
    .cover {
      display: block;
      width: 100%;
      margin-bottom: PX2REM(40px);
    }
  }
}

@include language-en {
  .About {
    .subTitle {
      letter-spacing: 0.5px;
    }
    .description {
      letter-spacing: 0.5px;
    }
    .h3 {
      @include s-large {
        margin-bottom: 13px;
      }
    }
  }
}
