
      ;
      $app_version: 'v1.0.0_5807321';
      $app_name: 'website';
      
@import "../../styles/utils.scss";

.Paijitang {
  --theme: #FFDFD1;
  --theme-gradient: linear-gradient(180deg, #FA884C 0%, #EB4B40 100%);
  --banner-title-color: #fff;
  --banner-subtitle-color: #fff;
  --project-name-color: #fff;
  --swiper-dot-active-color: #EC4E41;
}
