
      ;
      $app_version: 'v1.0.0_5807321';
      $app_name: 'website';
      
@import "../../../styles/utils.scss";

@include s-large {
  .Main {
  }
}

@include s-small {
  .Main {
    padding: 0 PX2REM(20px);
  }
}
