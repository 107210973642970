
      ;
      $app_version: 'v1.0.0_5807321';
      $app_name: 'website';
      
@import "../../../../styles/utils.scss";


@include s-large {
  .main {
    display: flex;
    align-items: flex-start;
    padding: 40px 0;

    .info {
      width: 400px;
      margin-right: 50px;
      flex-shrink: 0;

      .title {
        font-size: 24px;
        color: #333333;
        line-height: 32px;
        margin-bottom: 24px;
      }

      .subTitle {
        font-size: 16px;
        color: #333333;
        font-weight: bold;
        line-height: 28px;
        margin-bottom: 10px;
      }

      .description {
        font-size: 16px;
        color: #666666;
        line-height: 28px;
        margin-bottom: 20px;
      }

      .dots {
        display: flex;
        margin-bottom: 45px;

        .dot {
          width: 10px;
          height: 10px;
          border-radius: 50%;
          background: #F9E72C;
          margin-right: 6px;

          &.active {
            background: #3D3A39;
          }
        }
      }
    }

    .cover {
      flex: 1 1 750px;
      width: 750px;
    }
  }
}

@include s-small {
  .main {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    padding: PX2REM(40px) PX2REM(40px) 0;

    .info {
      flex: 1;

      .title {
        font-size: PX2REM(36px);
        color: #333333;
        line-height: PX2REM(54px);
        margin-bottom: PX2REM(20px);
        font-weight: bold;
      }

      .subTitle {
        font-size: PX2REM(32px);
        color: #333333;
        line-height: PX2REM(48px);
        margin-bottom: PX2REM(20px);
        font-weight: bold;
      }

      .description {
        font-size: PX2REM(32px);
        color: #666666;
        line-height: PX2REM(48px);
        margin-bottom: PX2REM(40px);
      }
    }

    .dots {
      display: flex;
      margin-bottom: PX2REM(60px);

      .dot {
        width: PX2REM(16px);
        height: PX2REM(16px);
        border-radius: 50%;
        background: #F9E72C;
        margin-right: PX2REM(10px);

        &.active {
          background: #3D3A39;
        }
      }
    }

    .cover {
      width: 100%;
      margin-bottom: PX2REM(20px);
    }
  }
}
