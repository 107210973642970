
      ;
      $app_version: 'v1.0.0_5807321';
      $app_name: 'website';
      
@import '../../styles/utils.scss';
@import '../../styles/variable.scss';

@include s-large {
  .Index {
    padding-bottom: 60px;
  }
  .breadcrumb {
    padding-bottom: 0;
  }
  .banner {
    height: 180px;
    img {
      width: auto;
      height: 100%;
      max-height: 100%;
      object-fit: cover;
    }
  }
  .sectionA {
    max-width: $pc-max-width;
    margin: 0 auto;
  }

  .title {
    font-size: 20px;
    margin-top: 60px;
    display: block;
  }

  .subTitle {
    font-size: 16px;
    color: #333;
    margin-top: 24px;
  }

  .content {
    margin-top: 20px;
    line-height: 1.75;
  }

  .divider {
    margin-top: 16px;
  }

  .serviceImg {
    width: 360px;
    height: 400px;
  }

  .protectionImage {
    width: 220px;
    height: 290px;
  }

  .quality {
    &:first-of-type {
      margin-top: 20px;
    }
  }
}

@include s-small {
  .Index {
    padding-bottom: PX2REM(100px);
  }
  .banner {
    height: PX2REM(360px);
    img {
      width: auto;
      height: 100%;
      max-height: 100%;
      object-fit: cover;
    }
  }
  .sectionA {
    margin-top: PX2REM(80px);
  }

  .container {
    margin-top: PX2REM(100px);
  }

  .title {
    display: block;
    font-size: PX2REM(40px);
    margin-top: PX2REM(100px);
    margin-left: PX2REM(40px);
  }

  .subTitle {
    display: block;
    font-size: PX2REM(32px);
    margin-top: PX2REM(60px);
    color: #333;
  }

  .content {
    font-size: PX2REM(32px);
    margin-top: PX2REM(20px);
  }

  .tips {
    font-size: PX2REM(20px);
    margin-top: PX2REM(30px);
    color: #999;
  }

  .divider {
    margin: PX2REM(40px) PX2REM(40px) 0;
  }

  .serviceImg {
    width: PX2REM(670px);
    height: PX2REM(744px);
    margin-top: PX2REM(40px);
  }

  .iconItems {
    padding: PX2REM(40px);
    margin-top: PX2REM(40px);
    background: #FEFBDF;
  }

  .protectionImages {
    margin-top: PX2REM(40px);
  }
  .protectionImage {
    width: PX2REM(220px);
    height: PX2REM(290px);
  }
}