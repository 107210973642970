
      ;
      $app_version: 'v1.0.0_5807321';
      $app_name: 'website';
      
@import "../../../../styles/utils.scss";

.main {
  .infos {
    .item {
      .title {
        color: #333;
        font-weight: bold;
      }

      .description {
        color: #666;
      }
    }
  }
}

@include s-large {
  .main {
    display: flex;
    align-items: flex-start;

    .cover {
      width: 440px;
      height: 280px;
      margin-right: 50px;
    }

    .infos {
      .item {
        margin-bottom: 40px;

        .title {
          font-size: 16px;
          line-height: 28px;
        }

        .description {
          font-size: 16px;
          line-height: 28px;
        }
      }
    }
  }
}

@include s-middle {
  .Banner {
    .main {
      padding-left: $middle-padding;
      padding-right: $middle-padding;
    }
  }
}

@include s-small {
  .main {
    display: flex;
    align-items: flex-start;
    flex-direction: column-reverse;

    .cover {
      width: 100%;
    }

    .infos {
      .item {
        margin-bottom: PX2REM(40px);

        .title {
          font-size: 16px;
          line-height: 28px;
        }

        .description {
          font-size: 16px;
          line-height: 28px;
        }
      }
    }
  }
}
