
      ;
      $app_version: 'v1.0.0_5807321';
      $app_name: 'website';
      
@import "../../styles/utils";

@include s-large {
  .dots {
    display: flex;

    .dot {
      width: 10px;
      height: 10px;
      border-radius: 50%;
      background: var(--swiper-dot-default-color, #E5E5E5);
      margin-right: 6px;

      &.active {
        background: var(--swiper-dot-active-color, #F9E72C);
      }
    }
  }
}

@include s-small {
  .dots {
    display: flex;
    margin-bottom: PX2REM(60px);

    .dot {
      width: PX2REM(16px);
      height: PX2REM(16px);
      border-radius: 50%;
      background: var(--swiper-dot-default-color, #E5E5E5);
      margin-right: PX2REM(10px);

      &.active {
        background: var(--swiper-dot-active-color, #F9E72C);
      }
    }
  }
}
