
      ;
      $app_version: 'v1.0.0_5807321';
      $app_name: 'website';
      
@import "../../../styles/utils.scss";

.About {
  .section {
    color: #666666;

    .subTitle {
      color: #333333;
    }

    .list {
      color: #666666;
    }
  }
}

@include s-large {
  .badges {
    display: grid;
    grid-template-columns: auto auto;
    grid-template-rows: 96px;
    grid-gap: 6px;
  }

  .badge {
    height: auto;
    max-height: 100%;
  }

  .About {
    .section {
      margin-bottom: 20px;
      font-size: 16px;

      .subTitle {
        font-weight: bold;
      }

      .list {
        .item {
          position: relative;
          font-size: 16px;
          display: flex;
          align-items: flex-start;

          span {
            flex: 1;
          }


          &:before {
            content: '';
            display: flex;
            width: 8px;
            height: 8px;
            border-radius: 50%;
            background: #D8D8D8;
            margin-right: 8px;
            margin-top: 8px;
          }
        }
      }
    }

    .link {
      font-size: 16px;
      color: #333;
      margin-top: 50px;
      cursor: pointer;

      span {
        text-decoration: underline 2px #333;
      }

      .icon {
        width: 13px;
        height: 16px;
        margin-left: 5px;
      }
    }
  }
}

@include s-middle {}

@include s-small {
  .badges {
    display: grid;
    grid-template-columns: auto auto;
    grid-template-rows: PX2REM(120px);
    grid-gap: PX2REM(12px);
  }

  .badge {
    height: auto;
    max-height: 100%;
  }

  .About {
    padding-bottom: PX2REM(40px);

    .section {
      margin-bottom: PX2REM(40px);
      font-size: PX2REM(32px);

      .subTitle {
        font-weight: bold;
        margin-bottom: PX2REM(20px);
      }

      .list {
        padding-left: 0;

        .item {
          position: relative;
          font-size: PX2REM(32px);
          display: flex;
          align-items: flex-start;
          margin-bottom: PX2REM(20px);

          &:before {
            content: '';
            display: flex;
            flex-shrink: 0;
            width: 8px;
            height: 8px;
            margin-top: PX2REM(16px);
            border-radius: 50%;
            background: #D8D8D8;
            margin-right: 8px;
          }
        }
      }
    }

    .link {
      font-size: PX2REM(32px);
      color: #333;
      margin-bottom: PX2REM(40px);

      span {
        text-decoration: underline 2px #333;
      }

      .icon {
        width: 13px;
        height: 16px;
        margin-left: PX2REM(20px);
      }
    }
  }
}