
      ;
      $app_version: 'v1.0.0_5807321';
      $app_name: 'website';
      
@import "../../../styles/utils.scss";

.Banner {
  .main {
    display: flex;
    align-items: center;
    justify-content: flex-start;

    .logoBox {
      .name {
        font-weight: bolder;
        color: var(--project-name-color, #333333);
      }
    }

    .slogan {
      .title {
        font-weight: bolder;
        color: var(--banner-title-color, #333333);
      }

      .text {
        color: var(--banner-title-color, #333333);
      }
    }
  }
}

@include s-large {
  .Banner {
    display: flex;
    align-items: stretch;
    min-height: 180px;

    .main {
      width: $pc-max-width;
      margin: 0 auto;
      display: flex;
      align-items: center;

      .logoBox {
        align-self: flex-start;
        flex-shrink: 0;
        margin-top: 50px;
        margin-right: 64px;

        .logo {
          width: auto;
          height: var(--large-logo-height, 40px);
        }

        p {
          font-size: 16px;
          color: #666666;
          letter-spacing: 1px;
          margin-top: 10px;
        }
      }

      .slogan {
        .title {
          font-size: 28px;
          line-height: 40px;
          margin-bottom: 20px;
        }

        .text {
          height: 26px;
          font-size: 18px;
          line-height: 26px;
        }
      }
    }
  }
}

@include s-small {
  .Banner {
    padding: PX2REM(60px);

    .main {
      flex-direction: column;
      align-items: flex-start;
      //min-height: PX2REM(360px);

      .logoBox {
        margin-bottom: PX2REM(40px);

        .logo {
          height: PX2REM(40px);
          margin-bottom: PX2REM(20px);
        }
      }

      .slogan {
        .title {
          font-size: PX2REM(36px);
          margin-bottom: PX2REM(24px);
        }

        .text {
          font-size: PX2REM(26px);
        }
      }
    }
  }
}
