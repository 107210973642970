
      ;
      $app_version: 'v1.0.0_5807321';
      $app_name: 'website';
      
@import '../../styles/utils.scss';

.image {
  position: relative;
}

.videoCover {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

@include s-large {
  .video {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, .6);
    z-index: 999;

    .videoBox {
      position: absolute;
      top: 45%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 50%;

      .close {
        position: absolute;
        bottom: -100px;
        left: 50%;
        transform: translateX(-50%);
        display: flex;
        align-items: center;
        justify-content: center;
        width: 40px;
        height: 40px;
        border-radius: 50%;
        border: 2px solid #fff;
      }
    }
  }
  .inverts {
    justify-content: flex-end;
  }
  .container {
    margin-top: 60px;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;

    .content {
      flex: 1;

      .title {
        font-size: 20px;
        color: #333;
      }

      .children {
        margin-top: 20px;
        font-size: 16px;
        color: #666;
      }
    }

    .swiper {
      margin-left: 50px;
      
      .swiperItem {
        .cover {
          width: auto;
          height: 100%;
          max-height: 100%;
          object-fit: cover;
        }
      }

      .dots {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        margin-top: 20px;
  
        .dot {
          width: 10px;
          height: 10px;
          border-radius: 50%;
          background: #F9E72C;
          margin-right: 6px;
  
          &.active {
            background: #3D3A39;
          }
        }
      }
    }

    .images {
      display: flex;
      margin-left: 50px;

      .image {
        margin-left: 40px;
        flex: 1;
        display: flex;
        flex-direction: column;
        align-items: center;


        &:first-child {
          margin-left: 0;
        }

        img {
          width: 100%;
          max-height: 100%;
          object-fit: cover;
        }

        span {
          font-size: 14px;
          color: #666;
          margin-top: 10px;
          text-align: center;
        }

      }

    }
  }
}

@include s-small {
  .video {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, .6);
    z-index: 999;

    .videoBox {
      position: absolute;
      top: 45%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 100%;

      .close {
        position: absolute;
        bottom: PX2REM(-100px);
        left: 50%;
        transform: translateX(-50%);
        display: flex;
        align-items: center;
        justify-content: center;
        width: PX2REM(60px);
        height: PX2REM(60px);
        border-radius: 50%;
        border: 2px solid #fff;
      }
    }
  }
  .container {
    display: flex;
    flex-direction: column;
    padding: 0 PX2REM(40px);

    .title {
      font-size: PX2REM(40px);
      color: #333;
      display: block;
    }

    .children {
      margin-top: PX2REM(40px);
      font-size: PX2REM(32px);
      color: #666;
      line-height: 1.75;
    }

    .images {
      display: flex;
      .image {
        flex: 1;
        display: flex;
        flex-direction: column;
        align-items: center;

        img {
          width: 100%;
          max-height: 100%;
          object-fit: cover;
        }
        span {
          font-size: PX2REM(24px);;
          margin-top: PX2REM(20px);
          color: #666;
          text-align: center;
        }
      }
    }

    .swiper {
      margin-top: PX2REM(60px);
      
      .swiperItem {
        .cover {
          width: auto;
          height: 100%;
          max-height: 100%;
          object-fit: cover;
        }
      }

      .dots {
        display: flex;
        align-items: center;
        margin-top: PX2REM(20px);
  
        .dot {
          width: PX2REM(16px);
          height: PX2REM(16px);
          border-radius: 50%;
          background: #F9E72C;
          margin-right: PX2REM(10px);
  
          &.active {
            background: #3D3A39;
          }
        }
      }
    }
  }
}
