
      ;
      $app_version: 'v1.0.0_5807321';
      $app_name: 'website';
      
@import "../../styles/utils.scss";

.Section {

  &.showBottomLine {
    @extend .bottomLine;
  }

  .name {
    color: #333333;
  }
}

@include s-large {
  .Section {
    max-width: 1200px;
    margin: 60px auto 0;

    &.showBottomLine {
      @extend .bottomLine;
      padding-bottom: 24px;
    }

    .name {
      font-size: 20px;
      margin-bottom: 24px;
      font-weight: bold;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .main {
      border-radius: 5px;

      &.border {
        border: 1px solid #D8D8D8;
      }
    }
  }

  .sectionFull {
    max-width: 100%;
    margin-top: 60px;

    .name {
      max-width: 1200px;
      font-size: 20px;
      color: #333333;
      line-height: 30px;
      margin: 0 auto 24px;
      font-weight: bold;
    }

    .container {
      max-width: 1200px;
      margin: 0 auto;
    }
  }
}

@include s-middle {
  .Section {
    margin: 60px $middle-padding 0;
  }

  .sectionFull {
    .name {
      margin-left: 20px;
    }
  }
}

@include s-small {
  .Section {
    margin: PX2REM(80px) PX2REM(40px) PX2REM(100px);

    .name {
      font-size: 20px;
      margin-bottom: PX2REM(24px);
      font-weight: bolder;
      //padding-left: PX2REM(20px);
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: flex-start;
    }

    .nameExtra {
      margin-top: PX2REM(24px);
    }

    .main {
      border-radius: PX2REM(5px);

      &.border {
        border: 1px solid #D8D8D8;
      }
    }
  }

  .sectionFull {
    max-width: 100%;
    margin-top: PX2REM(60px);

    .name {
      font-size: PX2REM(40px);
      color: #222;
      line-height: PX2REM(40px);
      margin-left: PX2REM(40px);
      font-weight: bold;
      margin-bottom: PX2REM(40px);
    }

    .container {
      max-width: 1200px;
      margin: 0 auto;
    }
  }
}