
      ;
      $app_version: 'v1.0.0_5807321';
      $app_name: 'website';
      
@import "../../../../styles/utils.scss";

.units {
  display: flex;
  align-items: stretch;
  flex-wrap: wrap;

  .item {
    flex: 1;
    display: flex;
    align-items: flex-start;
    flex-direction: column;

    .name {
      flex: 1 1 auto;
      color: #666666;
      .unit {
        color: #999999;
      }
    }

    sup {
      color: #999999;
      top: -0.8em;
    }

    .value {
      font-size: 32px;
      line-height: 32px;
      font-weight: bolder;
    }
    .description {
      color: #999999;
    }
  }
}

@include s-large {
  .units {
    .item {
      // TODO
      //margin: 0 60px 30px 0;
      .name {
        font-size: 16px;
        line-height: 28px;
        // TODO
        margin-bottom: 10px;
      }
      sup {
        font-size: .5em;
      }
      .value {
        font-size: 32px;
        line-height: 32px;
        font-weight: bolder;
      }

      .unit {
        font-size: 12px;
        font-weight: normal;
      }

      .values {
        display: flex;
        .value-item {
          font-size: 16px;
          color: #666;
          margin-right: 60px;
          font-weight: normal;

          strong {
            font-size: 32px;
            color: #333;
            font-weight: bolder;
          }
        }
      }
      .description {
        font-size: 12px;
      }
    }
  }
}

@include s-middle {
  .Banner {
    .main {
      padding-left: $middle-padding;
      padding-right: $middle-padding;
    }
  }
}

@include s-small {
  .units {
    flex-wrap: wrap;
    //flex-direction: column;

    .item {
      flex: 0 0 50%;
      margin-bottom: PX2REM(40px);

      .name {
        font-size: PX2REM(28px);
        line-height: 28px;
        margin-bottom: PX2REM(20px);

        .unit {
          font-size: PX2REM(22px);
        }
      }

      sup {
        font-size: .5em;
      }

      .value {
        display: block;
        font-size: PX2REM(68px);
        font-weight: bolder;

        span {
          font-size: PX2REM(28px);
          font-weight: normal;
        }
      }

      .values {
        display: flex;
        flex-wrap: wrap;
        flex-direction: column;

        .value-item {
          margin-top: PX2REM(30px);
          font-size: PX2REM(28px);
          color: #666;
          font-weight: normal;

          &:first-child {
            margin-top: 0;
          }

          span {
            font-size: PX2REM(68px);;
            color: #333;
            font-weight: bolder;
          }
        }

      }
      .description {
        font-size: PX2REM(24px);
      }
    }
  }
}
