
      ;
      $app_version: 'v1.0.0_5807321';
      $app_name: 'website';
      
@import "../../../styles/utils.scss";

.Banner {
  text-align: center;
}

$height: 360px;

.action {
  position: absolute;
  top: 60%;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 20px;
  background: linear-gradient(180deg, #3D3A39 0%, #000000 100%);
  border-radius: 25px;
  user-select: none;

  .text {
    color: #ffff;
    margin-right: 5px;
  }
}

@include s-large {
  .container {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, .6);
    z-index: 999;

    .videoBox {
      position: absolute;
      top: 45%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 50%;

      .close {
        position: absolute;
        bottom: -100px;
        left: 50%;
        transform: translateX(-50%);
        display: flex;
        align-items: center;
        justify-content: center;
        width: 40px;
        height: 40px;
        border-radius: 50%;
        border: 2px solid #fff;
        cursor: pointer;
      }
    }
  }

  .Banner {
    width: 100%;
    height: $height;
  }

  .wrapper {
    width: $pc-max-width;
    height: $height;
    margin: 0 auto;
  }

  .slideItem {
    position: relative;

    img {
      width: 100%;
      height: $height;
    }
  }
}

@include s-small {
  .container {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, .6);
    z-index: 999;

    .videoBox {
      position: absolute;
      top: 45%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 100%;

      .close {
        position: absolute;
        bottom: PX2REM(-100px);
        left: 50%;
        transform: translateX(-50%);
        display: flex;
        align-items: center;
        justify-content: center;
        width: PX2REM(60px);
        height: PX2REM(60px);
        border-radius: 50%;
        border: 2px solid #fff;
      }
    }
  }
  .Banner {
    height: PX2REM(360px);
  }

  .wrapper {
    height: 100%;
    padding: 0 PX2REM(60px);
  }

  .slideItem {
    height: PX2REM(360px);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    img {
      width: 100%;
      margin-bottom: PX2REM(45px);
      margin-top: PX2REM(-80px);
    }

    .action {
      padding: PX2REM(18px) PX2REM(40px);
      border-radius: PX2REM(40px);
    }
  }
}
