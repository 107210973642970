
      ;
      $app_version: 'v1.0.0_5807321';
      $app_name: 'website';
      
@import "../../../styles/utils.scss";
@import "../../../styles/snippet.scss";

.mainContainer {
  @extend .bottomLine;
}

.main {
  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: linear-gradient(180deg, #FFFFFF 0%, #F0F0F0 100%);
    box-shadow: 0px 1px 0px 0px rgba(0, 0, 0, 0.05);

    .icon {
      flex-shrink: 0;
      width: 16px;
      height: 16px;
      background: url('../../../assets/icon-add.png') no-repeat center/cover;
      cursor: pointer;

      &.reduce {
        background: url('../../../assets/icon-reduce.png') no-repeat center;
      }
    }
  }

  .section {
    .headerText {
      @extend .subtitle;
    }

    .description {
      color: #666;
    }
  }
}

@include s-large {
  .main {
    .header {
      width: auto;
      height: 80px;
      font-size: 20px;
      padding: 0 50px;
      border-top: 1px solid rgba(0, 0, 0, 0.05);
      cursor: pointer;

      .title {
        font-weight: normal;
      }
    }

    .panelBox {
      overflow: hidden;
      padding: 20px;
    }

    .panel {
      display: flex;
      min-height: 240px;

      .sections {
        padding: 20px 0 0 30px;

        .section {
          flex: 1;
          margin-right: 50px;
          font-size: 16px;

          .headerText {}

          .description {}
        }
      }

      .cover {
        width: 360px;
        height: 240px;
      }
    }
  }
}

@include s-small {
  .main {
    .header {
      width: auto;
      min-height: PX2REM(160px);
      font-size: PX2REM(32px);
      padding: PX2REM(30px);
      border-top: 1px solid rgba(0, 0, 0, 0.05);

      .title {
        margin-right: PX2REM(20px);
      }

      .iconBox {
        flex-shrink: 0;
        display: flex;
        justify-content: flex-end;
        width: PX2REM(50px);
      }
    }

    .panelBox {
      overflow: hidden;
    }

    .panel {
      display: flex;

      .sections {
        padding: PX2REM(50px) PX2REM(30px);

        .section {
          flex: 1;
          font-size: PX2REM(32px);

          .headerText {
            margin-bottom: PX2REM(40px);
          }

          .description {}
        }
      }

      .cover {
        display: none;
      }
    }
  }
}
