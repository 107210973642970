
      ;
      $app_version: 'v1.0.0_5807321';
      $app_name: 'website';
      
@import "../../styles/utils.scss";

.Main {
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    flex-shrink: 0;
  }
}

@include s-large {
  .Main {
    img {
      width: 12px;
      height: 24px;
    }
  }
}


@include s-small {
  .Main {
    img {
      width: PX2REM(24px);
      height: PX2REM(48px);
    }
  }
}
