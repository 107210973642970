
      ;
      $app_version: 'v1.0.0_5807321';
      $app_name: 'website';
      
@import "../../styles/utils.scss";

.main {
  --swiper-dot-active-color: #F9E72C;
  --swiper-dot-default-color: #E5E5E5;
}

