
      ;
      $app_version: 'v1.0.0_5807321';
      $app_name: 'website';
      
@import '../../styles/utils.scss';
@import '../../styles/variable.scss';

.tableData {
  max-width: $pc-max-width;
  margin: 40px auto;
  border: 1px solid #ececec;
}

.tableRow {
  display: flex;
  border-bottom: 1px solid #ececec;
  font-weight: 500;

  &:last-of-type {
    border: none;
  }

  &.tableHead {
    background-color: #f3f4fa;
  }
}

.tableLeft {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50%;
  border-right: 1px solid #ececec;
}

.tableRight {
  width: 50%;
  padding: 12px;
}

.tableCell {
  text-align: center;
  margin-bottom: 10px;

  &:last-of-type {
    margin-bottom: 0;
  }
}

.tableLink {
  color: #0091FF;
  text-decoration: underline;
}

@include s-large {
  .Index {
    padding-bottom: 60px;
  }

  .breadcrumb {
    padding-bottom: 0;
  }
}

@include s-small {

  .Index {
    padding-bottom: PX2REM(100px);
  }
}
