
      ;
      $app_version: 'v1.0.0_5807321';
      $app_name: 'website';
      
@import "../../../styles/utils.scss";

.Issue {
  display: flex;

  .description {
    color: #666666;
  }
}

@include s-large {
  .Section {
    margin-bottom: 60px;
  }
  .Issue {
    display: flex;

    .cover {
      margin-right: 50px;
      width: 670px;
      height: 380px;

      img {
        width: 100%;
        height: 100%;
      }
    }

    .description {
      flex: 1;
      font-size: 16px;
    }
  }

}

@include s-middle {

}

@include s-small {
  .Issue {
    flex-direction: column-reverse;

    .cover {
      width: auto;
      height: PX2REM(420px);
      margin: PX2REM(40px) 0;

      img {
        width: 100%;
        height: 100%;
      }
    }

    .description {
      flex: 1;
      font-size: 16px;
    }
  }
}
