
      ;
      $app_version: 'v1.0.0_5807321';
      $app_name: 'website';
      
.Icon {
  display: inline-block;
  width: 1em;
  height: 1em;
  mask-size: 100% 100%;
  mask-repeat: no-repeat;
  mask-position: center center;
  background-color: currentColor;
}

.Menu {
  mask-image: url('./icons/icon-menu.svg');
}

.Close {
  mask-image: url('./icons/icon-close.svg');
}

.Play {
  mask-image: url('./icons/icon-play.svg');
}

.ArrowDown {
  mask-image: url('./icons/icon-down.svg');
}

.ArrowUp {
  mask-image: url('./icons/icon-down.svg');
  transform: rotateZ(180deg);
}

.Add {
  mask-image: url('./icons/icon-add.svg');
  transform: rotateZ(180deg);
}

.Reduce {
  mask-image: url('./icons/icon-reduce.svg');
  transform: rotateZ(180deg);
}
