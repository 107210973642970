
      ;
      $app_version: 'v1.0.0_5807321';
      $app_name: 'website';
      
@import "../../../../styles/utils.scss";

.Steps {
  background: linear-gradient(180deg, #FDF557 0%, #F9E52C 100%);

  .main {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;

    .item {
      font-weight: bold;
    }
  }
}

@include s-large {
  .Steps {
    display: flex;
    align-items: center;
    height: 240px;

    .main {
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;
      width: 100%;
      max-width: $pc-max-width;
      margin: 0 auto;

      .item {
        flex: 1;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        img {
          width: 100px;
          height: 100px;
          margin-bottom: 18px;
        }
      }
    }
  }
}

@include s-small {
  .Steps {
    display: flex;
    align-items: center;
    padding: PX2REM(60px) PX2REM(36px) 0;

    .main {
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;
      flex-wrap: wrap;
      width: 100%;
      margin: 0 auto;

      .item {
        flex: 0 0 33.333%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-bottom: PX2REM(60px);

        .name {
          text-align: center;
        }
        img {
          width: PX2REM(120px);
          height: PX2REM(120px);
          margin-bottom: PX2REM(20px);
        }
      }
    }
  }
}
