@import "./variable.scss";

.bottomLine {
  position: relative;
  padding-bottom: 8px;

  &:before,
  &:after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    background-color: var(--theme, #F9E72C);
  }

  &:before {
    bottom: 6px;
    height: 2px;
  }

  &:after {
    bottom: 0;
    height: 4px;
    border-radius: 0 0 5px 5px;
  }
}

.title {
  font-weight: bolder;
  color: #333333;
}

.subtitle {
  font-weight: bold;
  color: #333333;
  line-height: 28px;
}

.description {
  color: #666666;
  font-weight: normal;
  line-height: 28px;
}
