
      ;
      $app_version: 'v1.0.0_5807321';
      $app_name: 'website';
      
@import "../../../styles/utils.scss";

.items {
  display: flex;
}

@include s-large {
  .items {
    flex-wrap: wrap;
  }
  .item {
    min-width: 270px;
    max-width: 364px;
    display: flex;
    align-items: flex-start;
    margin-right: 40px;
    margin-top: 42px;

    .icon {
      width: 48px;
      height: 48px;
    }

    .right {
      flex: 1;
      display: flex;
      flex-direction: column;
      margin-left: 16px;

      strong {
        font-size: 16px;
        color: #333;
        line-height: 28px;
      }
      p {
        font-size: 14px;
        color: #666;
        line-height: 22px;
      }
    }
  }
}

@include s-middle {

}

@include s-small {
  .items {
    flex-direction: column;
  }
  .item {
    display: flex;
    margin-top: PX2REM(60px);

    &:first-child {
      margin-top: 0;
    }

    .icon {
      width: PX2REM(120px);
      height: PX2REM(120px);

      img {
        width: auto;
        height: 100%;
        max-height: 100%;
        object-fit: cover;
      }
    }

    .right {
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin-left: PX2REM(40px);

      strong {
        font-size: PX2REM(32px);
        color: #333;
      }
      p {
        font-size: PX2REM(28px);
        color: #666;
        margin-top: PX2REM(8px);
      }
    }
  }
}
