
      ;
      $app_version: 'v1.0.0_5807321';
      $app_name: 'website';
      
@import "../../styles/utils.scss";

.swiperContainer, .swiperItem {
  width: 100%;
  height: 100%;
}

.swiper {
  max-width: 100%;
  overflow: hidden;
}

@include s-large {
  .swiperBox {
    width: 1200px;
    overflow: hidden;
  }
}
