
      ;
      $app_version: 'v1.0.0_5807321';
      $app_name: 'website';
      
@import "../../styles/utils.scss";

.Media {

  .pageTitle {
    font-weight: bolder;
  }

  .title {
    font-weight: bold;
  }

  .more {
    font-weight: bold;
  }
}

@include s-large {
  .Media {
    padding-bottom: 60px;

    .main {
      max-width: 1200px;
      margin: 0 auto;
    }

    .pageTitle {
      font-size: 20px;
      color: #222222;
      line-height: 30px;
      margin-bottom: 24px;
      margin-top: 20px;
    }

    .list {
      .item {
        display: flex;
        flex-direction: column;
        margin-bottom: 60px;

        .title {
          font-size: 16px;
          color: #333333;
          line-height: 28px;
          margin-bottom: 24px;
        }

        .description {
          font-size: 14px;
          color: #666666;
          line-height: 28px;
        }

        .date {
          font-size: 14px;
          color: #999999;
          line-height: 28px;
        }

        .more {
          margin-top: 20px;
          cursor: pointer;
        }
      }
    }
  }
}

@include s-middle {
  .Media {
    padding-bottom: PX2REM(100px);

    .main {
      padding: 0 40px;
    }
  }
}

@include s-small {
  .Media {
    .main {
      padding: 0 PX2REM(40px);
    }

    .pageTitle {
      font-size: 20px;
      color: #222222;
      line-height: 30px;
      margin-bottom: PX2REM(60px);
      margin-top: PX2REM(60px);
    }

    .list {
      .item {
        display: flex;
        flex-direction: column;
        margin-bottom: PX2REM(100);

        .title {
          font-size: PX2REM(32px);
          color: #333333;
          line-height: PX2REM(40px);
          margin-bottom: PX2REM(20px);
        }

        .description {
          font-size: 14px;
          color: #666666;
          line-height: PX2REM(48px);
        }

        .date {
          font-size: 14px;
          color: #999999;
          line-height: 28px;
          margin-bottom: PX2REM(20px);
        }
      }
    }
  }
}
