
      ;
      $app_version: 'v1.0.0_5807321';
      $app_name: 'website';
      
@import "../../../../styles/utils.scss";


@include s-large {
  .main {
    display: flex;
    align-items: flex-start;

    .info {
      flex: 1;

      .title {
        font-size: 24px;
        color: #333333;
        line-height: 32px;
        margin-bottom: 24px;
      }

      .description {
        font-size: 16px;
        color: #666666;
        line-height: 28px;
        margin-bottom: 40px;
      }

      .icons {
        display: flex;
        align-items: flex-start;
        flex-wrap: wrap;

        .iconItem {
          flex: 0 0 33.333333%;
          display: flex;
          flex-direction: column;
          align-items: center;
          font-size: 16px;
          color: #666666;
          text-align: center;
          margin-bottom: 20px;
          &:nth-last-child(2), &:nth-last-child(1) {
            margin-bottom: 0;
          }

          img {
            width: 60px;
            height: 60px;
            border-radius: 50%;
            margin-bottom: 10px;
          }
        }
      }
    }

    .cover {
      flex: 0 0 750px;
      width: 750px;
      margin-right: 50px;
    }
  }
}

@include s-small {
  .main {
    display: flex;
    align-items: flex-start;
    flex-direction: column;

    .info {
      flex: 1;

      .title {
        font-size: PX2REM(36px);
        color: #333333;
        line-height: PX2REM(54px);
        margin-bottom: PX2REM(20px);
        font-weight: bold;
      }

      .subTitle {
        font-size: PX2REM(32px);
        color: #333333;
        line-height: PX2REM(48px);
        margin-bottom: PX2REM(20px);
        font-weight: bold;
      }

      .description {
        font-size: PX2REM(32px);
        color: #666666;
        line-height: PX2REM(48px);
        margin-bottom: PX2REM(40px);
      }

      .icons {
        display: flex;
        align-items: flex-start;
        flex-wrap: wrap;
        margin-top: PX2REM(60px);

        .iconItem {
          flex: 0 0 PX2REM(220px);
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          font-size: PX2REM(32px);
          color: #666666;
          line-height: 28px;
          margin-bottom: PX2REM(48px);
          text-align: center;

          &:nth-child(3n) {
            margin-right: 0;
          }

          img {
            width: PX2REM(120px);
            height: PX2REM(120px);
            border-radius: 50%;
            margin-bottom: PX2REM(28px);
          }
        }
      }
    }

    .cover {
      width: 100%;
      margin-bottom: PX2REM(20px);
    }
  }
}
