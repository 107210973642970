
      ;
      $app_version: 'v1.0.0_5807321';
      $app_name: 'website';
      
@import "../../../styles/utils.scss";

.tabContainer {

}

@include language-en {
  @include s-large{
    .tabContainer {
      .tabs {
        min-height: 106px;
      }
    }
  }
  @include s-large{
    .tabContainer {
      br{
        display: none;
      }
    }
  }
}
@include s-large {
  .tabContainer {
    .tabs {
      display: flex;
      align-items: stretch;
      background: linear-gradient(180deg, #FDF557 0%, #F9E52C 100%);

      .tabItem {
        position: relative;
        flex: 1 1 25%;
        display: inline-flex;
        align-items: stretch;
        justify-content: center;
        flex-wrap: wrap;
        word-break: break-word;
        color: #333;
        text-align: center;
        cursor: pointer;
        padding: 14px;

        > div {
          display: inline-flex;
          align-items: center;
          text-align: left;
        }

        &:hover {
          color: #333333;
          background: #D8D8D8;
          transition: background 0.3s ease-in-out;
        }

        &.active {
          background: #000000;
          color: #fff;

          &:before {
            content: '';
            position: absolute;
            bottom: 0;
            left: 50%;
            right: 0;
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 0 8px 8px 8px;
            border-color: transparent transparent #ffffff transparent;
            transform: translateX(-50%);
          }
        }
      }
    }

    .main {
      max-width: 100%;
      overflow: hidden;

      .box {
        display: flex;
        align-items: flex-start;
        flex-wrap: nowrap;
        transition: transform .2s ease-in-out;

        .tabPanel {
          width: 100%;
          flex-shrink: 0;
          display: none;

          &.active {
            display: block;
          }
        }
      }
    }
  }
}

@include s-small {
  .tabItem {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #333;
    padding: PX2REM(50px) PX2REM(30px);
    background: linear-gradient(180deg, #FDF557 0%, #F9E52C 100%);

    &.active {
      background: #000000;
      color: #fff;
    }

    .icon {
      display: flex;
      justify-content: flex-end;
      width: PX2REM(50px);
      flex-shrink: 0;
    }
    br {
      display: none;
    }
  }
}
