
      ;
      $app_version: 'v1.0.0_5807321';
      $app_name: 'website';
      
@import "../../../../styles/utils.scss";

.container {
  .title {
    color: #222222;
    font-weight: bold;
  }

  .main {
    .info {
      color: #666666;
    }
  }
  .tip {
    color: #999999;
  }
}

@include s-large {
  .container {
    padding: 40px 0;

    .title {
      font-size: 20px;
      line-height: 30px;
      margin-bottom: 24px;
    }

    .main {
      display: flex;
      align-items: flex-start;

      .cover {
        width: 440px;
        height: 280px;
        margin-right: 50px;
      }

      .info {
        .description {
          font-size: 16px;
          line-height: 28px;
          margin-bottom: 40px;
        }

        .units {
          //max-width: 80%;
        }
      }
    }
    .tip {
      font-size: 12px;
      margin-top: 40px;
    }
  }
}

@include s-middle {
  .container {
    .main, .title {
      padding-left: $middle-padding;
      padding-right: $middle-padding;
    }
  }
}

@include s-small {
  .container {
    padding: PX2REM(40px);

    .title {
      font-size: PX2REM(40px);
      margin-bottom: PX2REM(40px);
    }

    .main {
      display: flex;
      align-items: flex-start;

      .cover {
        width: 100%;
      }

      .info {
        .description {
          margin-bottom: PX2REM(40px);
          font-size: PX2REM(32px);
        }

        .units {
        }
      }
    }

    .tip {
      font-size: PX2REM(20px);
      margin-bottom: PX2REM(40px);
    }
  }
}
