
      ;
      $app_version: 'v1.0.0_5807321';
      $app_name: 'website';
      
@import "../../../../styles/utils.scss";

.Block {
  .unitName {
    color: #666666;

    .unit {
      color: #999999;
    }
  }

  .blockNum {
    color: #333333;
    font-weight: bolder;
  }
}

@include s-large {
  .Block {
    margin-top: 60px;

    .blockText {
      .blockUnits {
        display: flex;
        align-items: center;

        .unitItem {
          flex: 1;
          display: flex;
          align-items: flex-start;
          flex-direction: column;

          .unitName {
            font-size: 16px;
            line-height: 28px;
            margin-bottom: 20px;

            .unit {
              font-size: 12px;
            }
          }

          .blockNum {
            font-size: 32px;
            line-height: 32px;
          }
        }
      }
    }

    .note {
      font-size: 12px;
      margin-top: 40px;
      color: #999999;
    }

    .blockIconBox {
      .blockTitle {
        font-size: 16px;
        color: #333333;
        line-height: 28px;
        font-weight: bold;
        margin-bottom: 20px;
      }

      .blockIcons {
        display: flex;
        align-items: center;
        flex-wrap: wrap;

        .IconBox {
          flex-shrink: 0;
          font-size: 14px;
          color: #666666;
          margin-right: 60px;
          margin-bottom: 20px;
          line-height: 28px;

          img {
            width: 28px;
            height: 28px;
            margin-right: 8px;
          }
        }
      }
    }
  }
}

@include s-small {
  .Block {
    margin-top: PX2REM(100px);

    .blockText {
      width: 100%;

      .blockUnits {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        margin-bottom: PX2REM(40px);

        .unitItem {
          display: flex;
          flex: 1 1 33.33333%;
          align-items: flex-start;
          justify-content: center;
          flex-direction: column;

          .unitName {
            font-size: PX2REM(28px);
            line-height: PX2REM(48px);
            margin-bottom: PX2REM(20px);

            .unit {
              font-size: PX2REM(22px);
            }
          }

          .blockNum {
            font-size: PX2REM(68px);
            line-height: PX2REM(68px);
          }
        }
      }
    }
    
    .note {
      font-size: PX2REM(20px);
      margin-top: PX2REM(-80px);
      margin-bottom: PX2REM(60px);
      color: #999999;
    }

    .blockIconBox {
      .blockTitle {
        font-size: PX2REM(32px);
        color: #333333;
        line-height: PX2REM(48px);
        margin-bottom: PX2REM(40px);
        font-weight: bold;
      }

      .blockIcons {
        display: flex;
        align-items: center;
        flex-wrap: wrap;

        .IconBox {
          flex-shrink: 0;
          width: 50%;
          font-size: PX2REM(32px);
          color: #666666;
          margin-bottom: PX2REM(30px);
          line-height: PX2REM(48px);

          img {
            width: PX2REM(48px);
            height: PX2REM(48px);
            margin-right: PX2REM(16px);
          }
        }
      }
    }
  }
}

@include language-en {
  @include s-small {
    .Block {
      .blockIconBox {
        .IconBox {
          width: 100%;
        }
      }
    }
  }

  @include s-large {
    .Block {
      .blockIconBox {
        .blockIcons {
          padding-right: 20px;

          .IconBox {
            width: auto;
            margin-right: 0;

            &:nth-child(3n+1) {
              flex: 0 0 210px;
              width: 210px;
            }

            &:nth-child(3n+2) {
              flex: 0 0 195px;
              width: 195px;
            }

            &:nth-child(3n) {
              flex: 1 1 auto;
            }

            &:last-child {
              flex: 1 1 auto;
              width: auto;
            }
          }
        }
      }
    }
  }

}