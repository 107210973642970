
      ;
      $app_version: 'v1.0.0_5807321';
      $app_name: 'website';
      
@import "../../styles/utils.scss";

.Main {
  max-width: $pc-max-width;
  margin: 0 auto;
}