
      ;
      $app_version: 'v1.0.0_5807321';
      $app_name: 'website';
      
@import "../../../styles/utils.scss";

.About {
  --theme: #F9E72C;
}

.About {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  @extend .bottomLine;

  .info {
    .title {
      font-size: 20px;
      color: #222;
    }

    .description {
      font-size: 16px;
      color: #666;
    }

    .link {
      display: flex;
      align-items: center;
      font-size: 16px;
      color: #333;
      margin-top: 50px;
      cursor: pointer;

      .icon {
        width: 13px;
        height: 16px;
        margin-left: 5px;
      }
    }
  }

  .list {
    .item {
      position: relative;
      display: flex;
      align-items: center;
      background: rgba(249, 231, 44, .15);
      border-radius: 5px;
      padding: 12px 20px 12px 50px;

      .icon {
        position: absolute;
        left: -30px;
        top: 50%;
        transform: translateY(-50%);
        width: 60px;
        height: 60px;
        border-radius: 50%;
      }

      .box {
        .name {
          color: #333333;
          font-size: 16px;
          font-weight: bold;
        }

        .subTitle {
          color: #666;
          font-size: 14px;
          font-weight: normal;
        }
      }
    }
  }
}

@include s-large {
  .About {
    display: flex;
    align-items: flex-start;
    width: $pc-max-width;
    margin: 0 auto;

    .info {
      flex: 1;
      margin-top: 44px;
      margin-bottom: 44px;
      margin-right: 20px;

      .title {
        font-weight: bold;
        margin-bottom: 20px;
        font-size: 20px;
      }

      .description {
        font-size: 16px;
      }
      .link {
        font-size: 16px;
        margin-top: 30px;
        cursor: pointer;

        span {
          border-bottom: 2px solid #333;
        }

        .icon {
          width: 13px;
          height: 16px;
          margin-left: 5px;
        }
      }
    }

    .list {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      align-items: flex-end;
      padding-left: 30px;
      width: 360px;
      max-width: 50%;

      .item {
        width: 100%;
        background: rgba(249, 231, 44, .15);
        border-radius: 5px;
        margin-top: 10px;
        min-height: 80px;

        &:last-child {
          margin-bottom: 10px;
        }

        .icon {
          left: -30px;
          top: 50%;
          width: 60px;
          height: 60px;
          border-radius: 50%;
        }

        .box {
          .name {
            font-size: 16px;
          }

          .subTitle {
            font-size: 14px;
          }
        }
      }
    }
  }
}
@include language-en {
  @include s-large {
    .About {
      .list{
        width: 450px;
        max-width: 50%;
      }
    }
  }
}
@include s-middle {
  .About {
    margin: 0 $middle-padding;
  }
}

@include s-small {
  .About {
    flex-direction: column;
    margin-top: PX2REM(80px);

    .info {
      .title {
        font-weight: bold;
        margin-bottom: PX2REM(40px);
        font-size: PX2REM(40px);
      }

      .description {
        width: 100%;
        font-size: PX2REM(32px);
      }
      .link {
        font-size: PX2REM(32px);
        margin-top: PX2REM(50px);
        font-weight: 500;

        span {
          text-decoration: underline PX2REM(2px) #333;
        }

        .icon {
          width: PX2REM(26px);
          height: PX2REM(32px);
          margin-left:PX2REM(10px);
        }
      }
    }

    .list {
      margin-top: PX2REM(60px);
      padding-left: PX2REM(60px);

      .item {
        width: auto !important;
        min-height: PX2REM(160px);
        padding-left: PX2REM(100px);
        border-radius: PX2REM(10px);
        margin: PX2REM(24px) 0;

        .icon {
          left: PX2REM(-60px);
          width: PX2REM(120px);
          height: PX2REM(120px);
        }

        .box {
          .name {
            font-size: PX2REM(32px);
          }

          .subTitle {
            font-size: PX2REM(28px);
          }
        }
      }
    }
  }
}
