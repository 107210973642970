
      ;
      $app_version: 'v1.0.0_5807321';
      $app_name: 'website';
      
@import "../../../styles/utils.scss";

.Section {

  .name {
    color: #333333;
  }
}

@include s-large {
  .Section {
    width: $pc-max-width;
    margin: 60px auto 0;

    .name {
      font-size: 20px;
      margin-bottom: 24px;
      font-weight: bold;
    }

    .main {
      border-radius: 5px;
      overflow: hidden;

      &.border {
        border: 1px solid #D8D8D8;
      }
    }
  }
}

@include s-middle {
  .Section {
    padding: 0 $middle-padding;
  }
}

@include s-small {
  .Section {
    margin: PX2REM(120px) auto 0;

    .name {
      font-size: 20px;
      margin-bottom: PX2REM(24px);
      font-weight: bold;
      padding-left: PX2REM(20px);
    }

    .main {
      border-radius: PX2REM(5px);
      padding: PX2REM(10px) PX2REM(10px) PX2REM(26px);

      &.border {
        border: 1px solid #D8D8D8;
      }
    }
  }
}
