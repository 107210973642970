
      ;
      $app_version: 'v1.0.0_5807321';
      $app_name: 'website';
      
@import "../../styles/utils.scss";

@include s-large {
  .large {
    display: contents;
  }
  .small {
    display: none;
  }
}

@include s-small {
  .large {
    display: none;
  }
  .small {
    display: contents;
  }
}
