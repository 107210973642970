
      ;
      $app_version: 'v1.0.0_5807321';
      $app_name: 'website';
      
@import "../../../styles/utils.scss";
@import '../../../styles/snippet.scss';

.Section {
  @extend .bottomLine;
}

.sectionHeader {
  position: relative;
  display: flex;
  align-items: center;

  .icon {
    flex-shrink: 0;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .introduce {
    color: #666;
    @extend .description;

    .keypoint {
      @extend .subtitle;
    }
  }
}

.main {
  display: flex;
  align-items: stretch;

  .container {
    background: #FEFBDF;

    .header {
      display: flex;
      align-items: center;
      @extend .title;

      .icon {
        border-radius: 50%;
      }
    }

    .body {
      .list {
        .listName {
          @extend .subtitle;
        }
        .values {
          .item {
            @extend .description;
          }
        }
      }
    }
  }
}

@include s-large {
  .sectionHeader {
    padding: 24px 53px 24px 0;

    .icon {
      flex-shrink: 0;
      width: 50px;
    }
  }

  .Section {
    padding-bottom: 24px;
  }

  .main {
    padding: 0 20px;

    .container {
      width: 380px;

      & {
        margin-right: 10px;
      }

      &:last-child {
        margin-right: 0;
      }

      .header {
        font-size: 16px;
        padding: 30px 15px 0 30px;
        line-height: 28px;
        margin-bottom: 20px;

        .icon {
          width: 60px;
          height: 60px;
          border-radius: 50%;
          margin-right: 20px;
        }
      }

      .body {
        padding: 0 24px 0 60px;

        .list {
          .listName {
            font-size: 16px;
            line-height: 24px;
            margin-bottom: 10px;
          }
          .values {
            margin-bottom: 30px;

            .item {
              font-size: 14px;
              line-height: 24px;
            }
          }
        }
      }
    }
  }
}

@include s-small {
  .sectionHeader {
    padding: 0;
    flex-direction: column-reverse;

    .icon {
      flex-shrink: 0;
      margin: PX2REM(50px) 0;
    }

    .introduce {
      font-size: 16px;
      line-height: 28px;

      .keypoint {
        font-weight: bold;
      }
    }
  }

  .Section {
    padding: PX2REM(38px) PX2REM(30px) PX2REM(30px) !important;
  }

  .main {
    margin-top: PX2REM(35px);
    flex-direction: column;

    .container {
      margin-bottom: PX2REM(20px);
      width: 100%;

      .header {
        font-size: PX2REM(32px);
        padding: PX2REM(60px) PX2REM(60px) 0 PX2REM(60px);
        line-height: PX2REM(56px);
        margin-bottom: PX2REM(40px);

        .icon {
          width: PX2REM(120px);
          height: PX2REM(120px);
          border-radius: 50%;
          margin-right: PX2REM(40px);
        }
        .name {
          display: flex;
          flex-direction: column;
        }
      }

      .body {
        padding-left: PX2REM(60px);
        padding-right: PX2REM(60px);

        .list {
          .listName {
            font-size: PX2REM(32px);
            line-height: PX2REM(48px);
            margin-bottom: PX2REM(20px);
          }
          .values {
            margin-bottom: 30px;

            .item {
              font-size: PX2REM(28px);
              line-height: PX2REM(48px);
            }
          }
        }
      }
    }
  }
}
